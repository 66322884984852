import { DEFAULT_LANGUAGE } from "../i18n/configuration";
import store from "../store/index";

const formLangPicker = (section) => {
    const lang = store.getState().selectedLanguage;
    if (!section[lang]) return section[DEFAULT_LANGUAGE.ENGLISH];
    return section[lang];
};

export default formLangPicker;
