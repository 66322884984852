import React, { useState } from "react";
import { useSelector, useDispatch } from 'react-redux'
import BlinkIdScanner from "./BlinkIdScanner";
import AilaTechScanner from "./AilaTechScanner";
import BlinkIdMobileScanner from "./BlinkIdMobileScanner";
import { workflowNextStep } from "../../../actions/Workflow";
import { FormattedMessage } from "react-intl";


const IdScanner = ({ label }) => {
    const [scanner, _setScanner] = useState(null);
    const date = useSelector(state => state.date); // eslint-disable-line
    const workflow = useSelector(state => state.workflow);
    const [field] = useState(workflow.form.getField(label));
    const dispatch = useDispatch();
    const nextStep = () => dispatch(workflowNextStep(workflow));

    const selectScanner = () => {
        try {
            const prefs = plugins.appPreferences; // eslint-disable-line
            prefs.fetch(setScanner, setScanner, "scanner");
        } catch {
            setScanner();
        }
    }

    const setScanner = (pref) => {
        let selectedScanner = null;
        if (window.cordova) {
            if (pref === "ailatech") {
                selectedScanner = <AilaTechScanner
                    onScan={onScan}
                    onError={onScanError}
                    onClose={onScanClose}
                />
            } else {
                selectedScanner = <BlinkIdMobileScanner
                    onScan={onScan}
                    onError={onScanError}
                    onClose={onScanClose}
                />;
            }
        } else {
            selectedScanner = <BlinkIdScanner
                onScan={onScan}
                onError={onScanError}
                onClose={onScanClose}
            />;
        }

        _setScanner(selectedScanner);
    };

    const onScan = (data) => {
        _setScanner(null);
        workflow.form.getField("user:dob").value = data.dob;
        workflow.form.getField("user:firstname").value = data.firstName;
        workflow.form.getField("user:lastname").value = data.lastName;
        console.log(workflow.form.getValues()) // TODO temporary fix do not remove
        if (field.triggerNext) {
            nextStep()
        }
    };

    const onScanError = () => {
        _setScanner(null);
        alert("Scanning Error")
    }

    const onScanClose = () => {
        _setScanner(null);
    }

    const renderScanner = () => {
        if (scanner) {
            return scanner
        }
    }

    return (
        <div className="scan-your-id-field">
            <div className="image" onClick={selectScanner}></div>
            <p className="option-label">
                <FormattedMessage id="idField.scanId.scan" />
            </p>
            {renderScanner()}
        </div>
    );
};

export default IdScanner;
