import React, { Component } from "react";
import Nav from "../nav/Nav";
import { Route, Routes } from "react-router-dom";
import Token from "./../template/Token";
import RouterContainer from "./RouterContainer";
class Template extends Component {
    constructor(props) {
        super(props);
        this.state = { currentTime: new Date().getHours() };
    }

    componentDidMount() {
        this.setBackground();
        this.interval = setInterval(
            () => this.setState({ currentTime: new Date().getHours() }),
            60000
        );
    }

    componentDidUpdate() {
        this.setBackground();
    }

    componentWillUnmount() {
        clearInterval(this.interval);
    }

    setBackground() {
        if (!document.querySelector(".main-container")) return;

        const time = this.currentTime();
        if (document.querySelector(`.main-container.${time}`)) return;

        document
            .querySelector(".main-container")
            .classList.add("main-container-transition");

        //When editing transition time, edit also transition in main-container css class!
        const transitionTime = 500;
        setTimeout(() => {
            this.cleanBackground();
            document.querySelector(".main-container").classList.add(time);
            document
                .querySelector(".main-container")
                .classList.remove("main-container-transition");
        }, transitionTime);
    }

    cleanBackground() {
        const listOfClass = ["morning", "afternoon", "evening"];
        for (let i = 0; i < listOfClass.length; i++) {
            document
                .querySelector(".main-container")
                .classList.remove(listOfClass[i]);
        }
    }

    currentTime() {
        if (this.state.currentTime >= 17) {
            return "evening";
        }
        if (this.state.currentTime >= 12) {
            return "afternoon";
        }

        return "morning";
    }

    renderContent() {
        return this.props.children;
    }

    render() {
        return (
            <div className="main-container">
                <RouterContainer>
                    <Nav />
                    <Routes>
                        <Route path="/token/:key" element={<Token />} />
                        <Route path="/" element={this.renderContent()} />
                    </Routes>
                </RouterContainer>
            </div>
        );
    }
}

export default Template;
