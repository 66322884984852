import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import TextField from "@material-ui/core/TextField";
import { interpolateTranslatedText } from "../../../helpers/interpolationHelper";

const mapStateToProps = (state) => {
    return {
        workflow: state.workflow,
        date: state.date,
        lang: state.selectedLanguage,
    };
};

class PostalCodeField extends Component {
    static propTypes = {
        label: PropTypes.string.isRequired,
    };

    constructor(props) {
        super(props);
        this.state = {
            field: this.props.workflow.form.getField(this.props.label),
        };
    }

    handleChange = (event) => {
        const target = event.target;
        const value = target.value;
        const field = this.state.field;
        field.value = value;
        this.setState({
            field: field,
        });
    };

    render() {
        const { field } = this.state;
        return (
            <TextField
                label={interpolateTranslatedText(field.title)}
                name="test"
                onChange={this.handleChange}
                value={field.value}
                error={field.error}
                className="postal-code-field"
                variant="standard"
            />
        );
    }
}

PostalCodeField = connect(mapStateToProps)(PostalCodeField);
export default PostalCodeField;
