import React, { Component } from "react";
import { connect } from "react-redux";
import { setWorkflowState } from "../../actions/Workflow";
import { LocationUserService } from "../../services/Resources";
import { FormattedMessage } from "react-intl";
import axios from "axios";
import Loader from "../loader/Loader";

const mapStateToProps = (state) => {
    return {
        workflowState: state.workflowState,
        access_token: state.access_token, //To trigger componenUpdate when new token is provided
    };
};

const mapActionsToProps = (dispatch) => {
    return {
        setWorkflowState: (data) => dispatch(setWorkflowState(data)),
    };
};

class LocationSelect extends Component {
    constructor(props) {
        super(props);
        this.state = {
            locations: null,
            searchValue: "",
        };
    }

    componentDidMount() {
        this.fetchLocations();
    }
    componentDidUpdate(prevProps) {
        if (prevProps.access_token !== this.props.access_token) {
            this.fetchLocations();
        }
    }
    fetchLocations = () => {
        LocationUserService.get({}, this.setLocations);
    };

    setLocations = (response) => {
        this.setState({
            locations: response.results,
        });
    };

    search = () => {
        console.log(this.state.searchValue);
    };

    renderLocation = (location) => {
        return (
            <div
                className="location-box"
                key={location.uuid}
                onClick={() => {
                    this.selectLocation(location);
                }}
            >
                <p>{location.name}</p>
                <p>
                    {location.zip_code} {location.province} {location.address}{" "}
                </p>
            </div>
        );
    };

    renderLocations = (text) => {
        const locations = this.state.locations.filter((x) =>
            x.name.toLowerCase().includes(text.toLowerCase())
        );

        if (locations.length) {
            return locations.map(this.renderLocation);
        } else {
            return (
                <div className="location-box text-center">
                    <FormattedMessage id="location.notFound" />
                </div>
            );
        }
    };

    selectLocation = (location) => {
        axios.defaults.headers.common["location"] = location.uuid;
        axios.defaults.headers.common["Authorization"] = location.uuid;
        this.props.setWorkflowState({ location });
        this.props.select();
    };

    render() {
        const { searchValue, locations } = this.state;
        if (locations) {
            return (
                <div className="location-wrapper">
                    <div className="search">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="currentColor"
                            className="bi bi-search"
                            viewBox="0 0 16 16"
                        >
                            <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                        </svg>
                        <FormattedMessage id="kiosk.searchInput">
                            {(placeholder) => (
                                <input
                                    value={searchValue}
                                    onChange={(e) =>
                                        this.setState({
                                            searchValue: e.target.value,
                                        })
                                    }
                                    type="text"
                                    name="password"
                                    placeholder={placeholder}
                                />
                            )}
                        </FormattedMessage>
                    </div>
                    <div className="location-container">
                        {this.renderLocations(searchValue)}
                    </div>
                </div>
            );
        } else {
            return <Loader />;
        }
    }
}

LocationSelect = connect(mapStateToProps, mapActionsToProps)(LocationSelect);

export default LocationSelect;
