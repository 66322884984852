import React, { useEffect } from "react";
import PropTypes from "prop-types";

const BlinkIdMobile = ({ onScan, onError, onClose }) => {
    const initializeBlinkId = () => {
        document.body.classList.add("cordova");
        var blinkIdRecognizer = new window.cordova.plugins.BlinkID.BlinkIdRecognizer();
        blinkIdRecognizer.returnFullDocumentImage = true;
        blinkIdRecognizer.returnFaceImage = true;

        var blinkidOverlaySettings = new window.cordova.plugins.BlinkID.BlinkIdOverlaySettings();
        var recognizerCollection = new window.cordova.plugins.BlinkID.RecognizerCollection(
            [blinkIdRecognizer /*, mrtdSuccessFrameGrabber */]
        );
        var licenseKeys = {
            android: process.env.REACT_APP_MICROBLINK_LICENSE_ANDROID,
            ios: process.env.REACT_APP_MICROBLINK_LICENSE_IOS,
        };

        window.cordova.plugins.BlinkID.scanWithCamera(
            (cancelled) => {
                document.body.classList.remove("cordova");

                // handle cancelled scanning
                if (cancelled) {
                    onClose()
                    return;
                }

                const resState = blinkIdRecognizer.result.resultState;

                if (resState === 2 || resState === 3) {
                    var blinkIdResult = blinkIdRecognizer.result;
                    const results = {};
                    results["firstName"] = blinkIdResult.firstName.split(" ")[0];
                    results["lastName"] = blinkIdResult.lastName;
                    results["dob"] =
                        blinkIdResult.dateOfBirth.day +
                        "-" +
                        blinkIdResult.dateOfBirth.month +
                        "-" +
                        blinkIdResult.dateOfBirth.year;
                    onScan(results);
                } else {
                    onError();
                }
            },

            // Register the error callback
            function errorHandler(e) {
                onError();
            },
            blinkidOverlaySettings,
            recognizerCollection,
            licenseKeys
        );
    };

    useEffect(initializeBlinkId);

    return (
        <div />
    );
};

export default BlinkIdMobile;

BlinkIdMobile.propTypes = {
    onScan: PropTypes.func.isRequired,
    onError: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
};
