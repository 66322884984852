import { LOCALES } from "../constants";

export const French = {
    [LOCALES.FRENCH]: {
        buttons: {
            login: "Connexion",
            logout: "Se déconnecter",
            close: "Fermer",
            more: "Suite ▼",
            less: "moins ▲",
            locate: "Localisez-moi",
            go: "va",
        },
        kiosk: {
            username: "Nom d'utilisateur",
            password: "Mot de passe",
            new: "Créer un nouveau compte",
            forgot: "Mot de passe oublié?",
            searchInput: "Rechercher...",
            popup: {
                timeout: "Délai d'attente dans",
                seconds: "Seconds",
                continue: "Continuer",
            },
        },
        errors: {
            usernameError: "Nom d'utilisateur invalide",
            usernameEmpty: "Nom d'utilisateur requis",
            passwordEmpty: "Mot de passe requis",
            popup: {
                loginFailedTitle: "Échec de la connexion",
                loginFailed:
                    "Veuillez entrer un nom d'utilisateur et un mot de passe corrects. ",
            },
        },
        nav: {
            button: "Connexion",
            language: {
                french: "Français",
                german: "Deutsche",
                spanish: "Española",
                english: "English",
                polish: "Polski",
                japanese: "日本語",
            },
        },
        menu: {
            patients: "Patients{br} et individus",
            healthcare: "Fournisseurs de soins{br} de santé et hôpitaux",
            corpoClient: "Clientèle",
            insurance: "Professionnels{br} de l'assurance",
        },
        satisfaction: {
            header: "Vous a-t-on rendu heureux ?",
            feedbackGreatv1:
                "Nous vous serions reconnaissants de prendre un moment pour partager votre expérience sur les réseaux sociaux.",
            feedbackGreatv2:
                "Ces commentaires nous aident à améliorer notre capacité à vous fournir les meilleurs soins possibles.",
            feedbackPoorly: "Merci de nous avoir fait part de vos commentaires",
            submit: "Soumettre",
        },
        errorPage: {
            notFound:
                "Désolé, nous n'avons pas trouvé la page que vous cherchiez. ",
            another:
                "il semble que quelque chose se soit mal passé, veuillez réessayer plus tard ou contactez-nous. ",
        },
        service: {
            title:
                "Société canadienne de diagnostic et d’essais des soins de santé",
            info: "Laboratoires et rendez-vous",
            description:
                "Trouvez des emplacements à proximité, planifiez et ne prenez pas de rendez-vous",
        },
        locationField: {
            openingHours: "Ouvre à 08:00",
            openingClosingHours: "Ouvert de 8h00 à 20h00",
            scheduler: "Cliquez pour planifier",
            more: "Suite",
        },
        location: {
            notFound: "Emplacement introuvable",
        },
        lookupForm: {
            welcome: "Bienvenu",
            info: "touchez-en un pour vous enregistrer",
            more: "Parlez-nous un peu de vous",
        },
        registrationForm: {
            header: "Appuyez sur la flèche vers l'avant si cela semble correct",
        },
        contactForm: {
            header: "Comment nous devons vous contacter?",
        },
        confirmationForm: {
            kiosk: {
                header: "Vous êtes enregistré!",
                content: "Prenez place et nous vous appellerons sous peu.",
            },
            online: {
                header: "Votre rendez-vous est confirmé!",
                qrCode: "Votre code QR est:",
            },
        },
        consentForm: {
            header: "Consentement et avis",
            show: "SPECTACLE",
            hide: "CACHER",
        },
        workflowStep: {
            what: "quel",
            when: "lorsque",
            where: "où",
            service: "Un service",
            location: "Emplacement",
            time: "Temps",
            register: "S'inscrire",
            confirmed: "Confirmé",
        },
        timeSlot: {
            header:
                "{dayPhase} - pour un rendez-vous {dayPhase}, choisissez une date future",
            morning: "Matin {timeRange}",
            afternoon: "Après midi {timeRange}",
            evening: "Soir {timeRange}",
        },
        daySlots: {
            Today: "Aujourd'hui",
            Monday: "lundi",
            Tuesday: "Mardi",
            Wednesday: "Mercredi",
            Thursday: "jeudi",
            Friday: "Vendredi",
            Saturday: "samedi",
            Sunday: "dimanche",
            Beyond: "Au-delà",
        },
        idField: {
            scanId: {
                scan: "ID de numérisation",
                unable: "Impossible de numériser votre document",
            },
            noId: "Aucun identifiant",
        },
    },
};
