import { LOCALES } from "../constants";

export const Spanish = {
    [LOCALES.SPANISH]: {
        buttons: {
            login: "Acceso",
            logout: "Cerrar sesión",
            close: "Cerca",
            more: "más ▼",
            less: "menos ▲",
            locate: "Ubícame",
            go: "más",
        },
        kiosk: {
            username: "Nombre de usuario",
            password: "Contraseña",
            new: "Crear una nueva cuenta",
            forgot: "Se te olvidó tu contraseña ?",
            searchInput: "buscar...",
            popup: {
                timeout: "Tiempo de espera en",
                seconds: "Segundos",
                continue: "Continuar",
            },
        },
        errors: {
            usernameError: "Nombre de usuario no válido",
            usernameEmpty: "Nombre de usuario obligatorio",
            passwordEmpty: "Se requiere contraseña",
            popup: {
                loginFailedTitle: "Error al iniciar sesión",
                loginFailed:
                    "Ingrese un nombre de usuario y contraseña correctos. ",
            },
        },
        nav: {
            language: {
                french: "Français",
                german: "Deutsche",
                spanish: "Española",
                english: "English",
                polish: "Polski",
                japanese: "日本語",
            },
        },
        menu: {
            patients: "Pacientes{br} e individuos",
            healthcare: "Hospitales y proveedore{br}s de atención médica",
            corpoClient: "Clientes{br} corporativos",
            insurance: "Profesionales{br} de seguros",
        },
        satisfaction: {
            header: "¿Te hicimos feliz?",
            feedbackGreatv1:
                "Le agradeceríamos que se tomara un momento para compartir su experiencia en las redes sociales.",
            feedbackGreatv2:
                "Esta retroalimentación nos ayuda a mejorar nuestra capacidad para brindarle la mejor atención posible.",
            feedbackPoorly: "Gracias por darnos tu opinión.",
            submit: "Entregar",
        },
        errorPage: {
            notFound:
                "Lo sentimos, no pudimos encontrar la página que estabas buscando.",
            another:
                "Parece que algo salió mal, inténtelo de nuevo más tarde o comuníquese con nosotros. ",
        },
        service: {
            title: "Compañía canadiense de pruebas y diagnóstico sanitario",
            info: "Laboratorios y citas",
            description:
                "Encuentre ubicaciones cercanas, programe y no espere citas",
        },
        location: {
            notFound: "Ubicación no encontrada",
        },
        locationField: {
            openingHours: "Abre a las 8:00 am",
            openingClosingHours: "Abierto de 8:00 am a 8:00 pm",
            scheduler: "Haga clic para programar",
            more: "Más",
        },
        lookupForm: {
            welcome: "Bienvenidos",
            info: "toca uno para registrarse",
            more: "Cuéntanos un poco de ti",
        },
        registrationForm: {
            header: "Toca la flecha de avance si se ve bien",
        },
        contactForm: {
            header: "Cómo debemos contactarlo?",
        },
        confirmationForm: {
            kiosk: {
                header: "¡Estás registrado!",
                content: "toma asiento y te llamamos a la brevedad.",
            },
            online: {
                header: "¡Tu cita está confirmada!",
                qrCode: "Tu código QR es:",
            },
        },
        consentForm: {
            header: "Consentimiento y notificación",
            show: "SHOW",
            hide: "ESCONDER",
        },
        workflowStep: {
            what: "qué",
            when: "Cuándo",
            where: "dónde",
            service: "Servicio",
            location: "Localización",
            time: "Hora",
            register: "Registrarse",
            confirmed: "Confirmado",
        },
        timeSlot: {
            header:
                "{dayPhase}: para una cita de {dayPhase}, elija una fecha futura",
            morning: "Mañana {timeRange}",
            afternoon: "Tarde {timeRange}",
            evening: "Noche {timeRange}",
        },
        daySlots: {
            Today: "Hoy",
            Monday: "lunes",
            Tuesday: "martes",
            Wednesday: "miércoles",
            Thursday: "jueves",
            Friday: "viernes",
            Saturday: "sábado",
            Sunday: "domingo",
            Beyond: "Más allá de",
        },
        idField: {
            scanId: {
                scan: "ID de escaneo",
                unable: "No se puede escanear su documento",
            },
            noId: "Sin identificación",
        },
    },
};
