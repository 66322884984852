import Service from "./Api";

class Location extends Service {
    constructor() {
        super();
        this.url = "/api/location/:id/";
        this.urlParams = {
            id: "@id",
        };
    }
    localize = (lat, lng, success, error) => {
        return this.get(
            { params: { lat, lng, id: "localize" } },
            success,
            error
        );
    };
}

class LocationUser extends Service {
    constructor() {
        super();
        this.url = "/api/location_user/:id/";
        this.urlParams = {
            id: "@id",
        };
    }
}

class WorkflowTemplate extends Service {
    constructor() {
        super();
        this.url = "/api/workflow_template/:id/:action/";
        this.urlParams = {
            id: "@id",
            action: "@action",
        };
    }
}

class WorkflowTemplateStep extends Service {
    constructor() {
        super();
        this.url = "/api/workflow_template_step/:id/:action/";
        this.urlParams = {
            id: "@id",
            action: "@action",
        };
    }
}

class Workflow extends Service {
    constructor() {
        super();
        this.url = "/api/workflow/:id/:action/";
        this.urlParams = {
            id: "@id",
            action: "@action",
        };
    }
}

class WorkflowStep extends Service {
    constructor() {
        super();
        this.url = "/api/workflow_step/:id/:action/:actionId/";
        this.urlParams = {
            id: "@id",
            action: "@action",
            actionId: "@actionId",
        };
    }
}

class WorkflowForm extends Service {
    constructor() {
        super();
        this.url = "/api/workflow_form/:id/:action/";
        this.urlParams = {
            id: "@id",
            action: "@action",
        };
    }
}

class ScheduleGroup extends Service {
    constructor() {
        super();
        this.url = "/api/schedule/group/:id/:action/";
        this.urlParams = {
            id: "@id",
            action: "@action",
        };
    }
}

class Login extends Service {
    constructor() {
        super();
        this.url = "/oauth/token/";
        this.service.interceptors.response.use(
            Service.handleSuccess,
            this.handleError
        );
    }
    handleError = (error) => {
        return Promise.reject(error);
    };
}

class WorkflowSurveyToken extends Service {
    constructor() {
        super();
        this.url = "/api/token/:key";
        this.urlParams = {
            key: "@key",
        };
    }
}

export const WorkflowSurveyTokenService = new WorkflowSurveyToken();
export const LocationService = new Location();
export const LocationUserService = new LocationUser();
export const WorkflowTemplateService = new WorkflowTemplate();
export const WorkflowTemplateStepService = new WorkflowTemplateStep();
export const WorkflowService = new Workflow();
export const WorkflowStepService = new WorkflowStep();
export const WorkflowFormService = new WorkflowForm();
export const ScheduleGroupService = new ScheduleGroup();
export const LoginService = new Login();
