import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import ScheduleWidget from "./scheduleWidget/ScheduleWidget";
import { ScheduleGroupService } from "../../../services/Resources";
import moment from "moment";
import { setWorkflowState } from "../../../actions/Workflow";

const mapStateToProps = (state) => {
    return {
        workflow: state.workflow,
    };
};

const mapActionsToProps = (dispatch) => {
    return {
        setWorkflowState: (state) => dispatch(setWorkflowState(state)),
    };
};

class ScheduleField extends Component {
    static propTypes = {
        label: PropTypes.string.isRequired,
    };

    constructor(props) {
        super(props);
        this.state = {
            field: this.props.workflow.form.getField(this.props.label),
            locationField: this.props.workflow.form.getField(
                "appointment:location"
            ),
            slots: null,
        };
    }

    componentDidMount = () => {
        this.fetchSlots();
    };

    fetchSlots = (date) => {
        date = date || moment();
        ScheduleGroupService.get(
            {
                params: {
                    id: this.state.locationField.value,
                    action: "slots",
                    duration: 20,
                    date: date.format("YYYY-MM-DD"),
                    resolution: 5,
                },
            },
            this.setSlots
        );
    };

    setSlots = (response) => {
        this.setState({
            slots: response.slots,
        });
    };

    onChange = (value) => {
        const { field } = this.state;
        field.value = value;
        this.props.setWorkflowState({ datetime: value.start_date_time });
    };

    render() {
        return (
            <ScheduleWidget
                onChange={this.onChange}
                slots={this.state.slots}
                onDateChange={this.fetchSlots}
                value={this.state.field.value}
            />
        );
    }
}

ScheduleField = connect(mapStateToProps, mapActionsToProps)(ScheduleField);
export default ScheduleField;
