import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { update } from "../../../actions/Workflow";
import { LocationService } from "../../../services/Resources";
import { FormattedMessage } from "react-intl";

const mapStateToProps = (state) => {
    return {
        workflow: state.workflow,
    };
};

const mapActionsToProps = (dispatch) => {
    return {
        update: (data) => dispatch(update()),
    };
};

class LocateField extends Component {
    static propTypes = {
        label: PropTypes.string,
    };

    constructor(props) {
        super(props);
        this.state = {
            //    field:  this.props.workflow.form.getField(
            //        this.props.label
            //    )
        };
    }

    locate = () => {
        navigator.geolocation.getCurrentPosition(this.getUserLocation);
    };

    getUserLocation = (position) => {
        LocationService.localize(
            position.coords.latitude,
            position.coords.longitude,
            this.processAddress
        );
    };

    processAddress = (data) => {
        let postalCode = null;
        if (data.length) {
            const details = data[0];
            for (let chunk of details.address_components) {
                if (chunk.types.indexOf("postal_code") >= 0) {
                    postalCode = chunk.long_name;
                    break;
                }
            }
        }
        this.props.workflow.form.getField("user:location").value = postalCode;
        this.props.update();
    };

    render() {
        return (
            <div
                className="btn btn-secondary btn-large btn-locate-me"
                onClick={this.locate}
            >
                <FormattedMessage id="buttons.locate" />
            </div>
        );
    }
}

LocateField = connect(mapStateToProps, mapActionsToProps)(LocateField);
export default LocateField;
