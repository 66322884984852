import React from "react";
import propTypes from "prop-types";
import "../../scss/loader/loader.scss";

const Loader = ({ fixed, box, transparent }) => {
    const getFixedClass = () => {
        return fixed ? "loader-fixed" : "";
    };

    const getTransparentClass = () => {
        return transparent ? "loader-transparency" : "";
    };

    const getBoxClass = () => {
        return box ? "loader-box" : "";
    };

    return (
        <div
            className={`loader-container ${getTransparentClass()} ${getFixedClass()}`}
        >
            <div className={`loader ${getBoxClass()}`}>
                <div>
                    <div>
                        <div>
                            <div>
                                <div>
                                    <div></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

Loader.propTypes = {
    fixed: propTypes.bool,
    box: propTypes.bool,
    transparent: propTypes.bool,
};

export default Loader;
