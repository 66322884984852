import React from "react";
import { FormattedMessage } from "react-intl";

function IHaveNoIdField(props) {
    return (
        <div className="i-have-no-id-field" onClick={props.onClick}>
            <div className="image" />
            <p className="option-label">
                <FormattedMessage id="idField.noId" />
            </p>
        </div>
    );
}

export default IHaveNoIdField;
