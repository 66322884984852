import React, { Component } from "react";
import { connect } from "react-redux";
import { initWorkflow, workflowNextStep } from "../../actions/Workflow";
import { WorkflowService } from "../../services/Resources";
import TimeSelectForm from "../../components/forms/selectTimeForm/Form";
import LookupForm from "../../components/forms/kiosk/lookupForm/Form";
import ContactForm from "../../components/forms/contactForm/Form";
import ConsentForm from "../../components/forms/consentForm/Form";
import RegistrationForm from "../../components/forms/registrationForm/Form";
import ServiceSelectForm from "../../components/forms/kiosk/selectService/Form";
import ConfirmationForm from "../../components/forms/kiosk/confirmationForm/Form";
import LocationSelect from "../../components/locationSelect/LocationSelect";
import LoginComponent from "../../components/login/LoginComponent";
import Modal from "react-bootstrap/Modal";
import Loader from "../../components/loader/Loader";
import LangButton from "../../components/buttons/langButtons/langButton";
import SettingsButton from "../../components/buttons/settingsButton/settingsButton";
import { FormattedMessage } from "react-intl";
import ConnectionButton from "../../components/buttons/connectionButton/connectionButton";

const mapStateToProps = (state) => {
    return {
        workflowState: state.workflowState,
        workflow: state.workflow,
        date: state.date, // temporary to handle store update
        isLogin: state.isLogin,
    };
};

const mapActionsToProps = (dispatch) => {
    return {
        workflowInit: (workflow) => dispatch(initWorkflow(workflow)),
        workflowNextStep: (workflow) => dispatch(workflowNextStep(workflow)),
    };
};

class WorkflowKiosk extends Component {
    constructor(props) {
        super(props);
        this.state = {
            inactivity: null,
            currentlyOpen: "",
        };
        this.timer = null;
    }

    componentDidMount() {
        window.addEventListener("reload", this.createSchelduiling);
    }

    openLang = () => {
        if (this.state.currentlyOpen !== "lang") {
            this.setState({
                currentlyOpen: "lang",
            });
        } else {
            this.setState({
                currentlyOpen: "",
            });
        }
    };
    openSettings = () => {
        if (this.state.currentlyOpen !== "settings") {
            this.setState({
                currentlyOpen: "settings",
            });
        } else {
            this.setState({
                currentlyOpen: "",
            });
        }
    };

    activity = () => {
        this.resetTimer();
        if (this.state.inactivity === 0) {
            this.inactivityTimer();
        }
    };

    createSchelduiling = () => {
        WorkflowService.post(
            {
                data: {
                    workflow_template_slug: "schedule_kiosk",
                },
            },
            this.initializeWorkflow
        );
    };

    initializeWorkflow = (response) => {
        const { inactivity } = this.state;
        if (inactivity === null) {
            window.addEventListener("click", this.activity);
            window.addEventListener("keydown", this.activity);
        }
        this.resetTimer();
        WorkflowService.post(
            {
                params: {
                    id: response.uuid,
                    action: "initialize",
                },
            },
            this.loadWorkflow
        );
    };

    loadWorkflow = (response) => {
        this.props.workflowInit(response);
    };

    renderStep = () => {
        const { workflow } = this.props;
        if (workflow.displayStep) {
            return (
                <div className="app-form-wrapper">
                    <div className="app-form-background"></div>
                    {this.renderForm()}
                </div>
            );
        }
    };

    renderForm = () => {
        const { workflow } = this.props;
        const { uuid, sectionId } = workflow.form;

        switch (workflow.form.identifier) {
            case "kiosk_schedule_select_service":
                return <ServiceSelectForm form={uuid} step={sectionId} />;
            case "schedule_time_select":
                return <TimeSelectForm form={uuid} step={sectionId} />;
            case "registration":
                return <RegistrationForm form={uuid} step={sectionId} />;
            case "kiosk_lookup":
                return <LookupForm form={uuid} step={sectionId} />;
            case "communication":
                return <ContactForm form={uuid} step={sectionId} />;
            case "consents":
                return <ConsentForm form={uuid} step={sectionId} />;
            case "confirmation":
                return <ConfirmationForm form={uuid} step={sectionId} />;
            default:
                return (
                    <div>
                        <div>Form {workflow.form.identifier}</div>
                    </div>
                );
        }
    };

    renderView = () => {
        const { workflow } = this.props;

        if (!workflow) {
            return <Loader fixed={true} />;
        } else {
            return this.renderStep();
        }
    };

    inactivityTimer = () => {
        this.timer = setTimeout(() => {
            let { inactivity } = this.state;
            inactivity += 1;
            this.setState({
                inactivity: inactivity,
            });
            if (inactivity === 30) {
                this.createSchelduiling();
            } else {
                this.inactivityTimer();
            }
        }, 1000);
    };

    resetTimer = () => {
        clearTimeout(this.timer);

        this.setState({
            inactivity: 0,
        });
    };

    renderModal = () => {
        const { inactivity } = this.state;
        return (
            <Modal className="modal" show={inactivity > 20} centered>
                <Modal.Header>
                    <div className="modal-img"></div>
                </Modal.Header>

                <Modal.Body>
                    <h2>
                        <FormattedMessage id="kiosk.popup.timeout" />
                    </h2>
                    <h4>
                        {30 - inactivity}{" "}
                        <FormattedMessage id="kiosk.popup.seconds" />
                    </h4>
                </Modal.Body>

                <Modal.Footer>
                    <div className="modal-btn btn btn-primary">
                        <FormattedMessage id="kiosk.popup.continue" />
                    </div>
                </Modal.Footer>
            </Modal>
        );
    };

    render() {
        const { isLogin, workflowState } = this.props;
        if (!workflowState.location.name) {
            return (
                <LoginComponent>
                    <LocationSelect select={this.createSchelduiling} />
                </LoginComponent>
            );
        }
        if (isLogin) {
            return (
                <div className="workflow-container app">
                    {this.renderView()}
                    {this.renderModal()}
                    <ConnectionButton />
                    <LangButton
                        openLang={this.openLang}
                        currentlyOpen={this.state.currentlyOpen}
                    />
                    <SettingsButton
                        openSettings={this.openSettings}
                        currentlyOpen={this.state.currentlyOpen}
                    />
                </div>
            );
        }
    }
}

WorkflowKiosk = connect(mapStateToProps, mapActionsToProps)(WorkflowKiosk);
export default WorkflowKiosk;
