import React, { Component } from "react";
import PropTypes from "prop-types";
import WorkflowState from "../../../workflowState/WorfkflowState";
import Confetti from "react-confetti";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import { initWorkflow } from "../../../../actions/Workflow";
import { reload } from "../../../../helpers/reload";
import moment from "moment";

const mapStateToProps = (state) => {
    return {
        workflowState: state.workflowState,
        workflow: state.workflow,
    };
};

const mapActionsToProps = (dispatch) => {
    return {
        workflowInit: (workflow) => dispatch(initWorkflow(workflow)),
    };
};

class ConfirmationForm extends Component {
    static propTypes = {
        form: PropTypes.string.isRequired,
        step: PropTypes.number.isRequired,
    };

    constructor(props) {
        super(props);
        this.state = {
            data: this.props.workflowState,
            confetti: true,
        };
    }

    componentDidMount() {
        setTimeout(() => {
            this.setState({ confetti: false });
        }, 4900);

        setTimeout(() => {
            reload();
        }, 10000);
    }

    renderConfetti() {
        const { confetti } = this.state;
        if (confetti) {
            return (
                <Confetti
                    width={window.innerWidth}
                    height={window.innerHeight}
                />
            );
        }
    }

    render() {
        const { datetime } = this.state.data;
        const { address, zip_code, city } = this.state.data.location;
        return (
            <div className="container d-flex flex-column">
                <div className="m-0">
                    <WorkflowState completed={5} />
                </div>
                {this.renderConfetti()}
                <div className="form-container">
                    <div className="clearfix" />
                    <div className="row">
                        <div className="col">
                            <div className="col-12 head-info">
                                <p className="form-label">
                                    <FormattedMessage id="confirmationForm.online.header" />
                                </p>
                            </div>
                            <div className="col-12 form-row justify-content-center">
                                <div className="col-12 col-sm-8 informations-background">
                                    <main className="text-center">
                                        <b>
                                            {moment
                                                .parseZone(datetime)
                                                .format(`MM-DD-YYYY h:mm A`)}
                                        </b>
                                        <p className="m-0">{address}</p>

                                        <p className="m-0">
                                            {zip_code}, {city}
                                        </p>
                                    </main>
                                    <div className="text-center">
                                        <p className="m-2">
                                            <FormattedMessage id="confirmationForm.online.qrCode" />
                                        </p>
                                        <img
                                            alt="QR code"
                                            src={
                                                "https://upload.wikimedia.org/wikipedia/commons/thumb/4/41/QR_Code_Example.svg/240px-QR_Code_Example.svg.png"
                                            }
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default connect(mapStateToProps, mapActionsToProps)(ConfirmationForm);
