import React, { Component } from "react";
import PropTypes from "prop-types";
import ScheduleField from "../../fields/scheduleField/ScheduleField";
import WorkflowState from "../../workflowState/WorfkflowState";
import PrevButton from "../../controlButtons/PrevButton";
import NextButton from "../../controlButtons/NextButton";

class TimeSelectForm extends Component {
    static propTypes = {
        form: PropTypes.string.isRequired,
        step: PropTypes.number.isRequired,
    };

    render() {
        return (
            <div className="container">
                <div className="form-container float-none">
                    <PrevButton />
                    <NextButton />
                    <WorkflowState completed={2} />
                    <div className="clearfix" />
                    <div className="container-fluid mt-5">
                        <div className="row">
                            <div className="col">
                                <ScheduleField label="appointment:datetime" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default TimeSelectForm;
