import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Slider from "@material-ui/core/Slider";
import { interpolateTranslatedText } from "../../../helpers/interpolationHelper";

const mapStateToProps = (state) => {
    return {
        workflow: state.workflow,
        date: state.date,
        lang: state.selectedLanguage,
    };
};

class RangeSlider extends Component {
    static propTypes = {
        label: PropTypes.string.isRequired,
    };

    constructor(props) {
        super(props);
        this.state = {
            field: this.props.workflow.form.getField(this.props.label),
        };
    }

    handleChange = (event) => {
        const target = event.target;
        const value = target.value;
        const field = this.state.field;
        field.value = value;
        field.error = !value;
        this.setState({
            field: field,
        });
    };

    render() {
        const { field } = this.state;
        return (
            <>
                <Slider
                    label={interpolateTranslatedText(field.title)}
                    name={field.identifier}
                    onChange={this.handleChange}
                    type="range"
                    className="range-slider"
                    variant="standard"
                />
            </>
        );
    }
}

RangeSlider = connect(mapStateToProps)(RangeSlider);
export default RangeSlider;
