import React, { useState } from "react";
import LanguageSelect from "../../../containers/languageSelect/languageSelect";
import ButtonImg from "./img/langButton.svg";

const LangButton = (props) => {
    const [isOpen, setIsOpen] = useState(false);

    return (
        <div className="langButton" onClick={props.openLang}>
            {props.currentlyOpen === "lang" ? (
                <LanguageSelect setIsOpen={setIsOpen} />
            ) : null}
            <img
                className="language"
                onClick={() => setIsOpen(!isOpen)}
                alt="language"
                src={ButtonImg}
            />
        </div>
    );
};

export default LangButton;
