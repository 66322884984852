import React from "react";
import { useParams } from "react-router-dom";
import WorkflowSatisfactionSurvey from "./../../containers/workflowSatisfactionSurvey/WorkflowSatisfactionSurvey";

const Token = () => {
    const { key } = useParams();

    return <>{key && <WorkflowSatisfactionSurvey secureToken={key}/>}</>;
};

export default Token;
