import React, { Component } from "react";
import { connect } from "react-redux";
import { interpolateTranslatedText } from "../../../helpers/interpolationHelper";

const mapStateToProps = (state) => {
    return {
        workflow: state.workflow,
        lang: state.selectedLanguage,
    };
};

class InfoField extends Component {
    constructor(props) {
        super(props);
        this.state = {
            field: this.props.workflow.form.getField(this.props.label),
        };
    }

    render() {
        const { field } = this.state;
        return (
            <div className="info-field">
                <p className="content">
                    {interpolateTranslatedText(field.title)}
                </p>
            </div>
        );
    }
}

InfoField = connect(mapStateToProps)(InfoField);
export default InfoField;
