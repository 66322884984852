import React, { Component } from "react";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Checkbox from "@material-ui/core/Checkbox";
import checkedIcon from "./img/checked.png";
import unchedkedIcon from "./img/unchedked.png";
import { FormattedMessage } from "react-intl";
import { interpolateTranslatedText } from "../../../helpers/interpolationHelper";

const mapStateToProps = (state) => {
    return {
        workflow: state.workflow,
        lang: state.selectedLanguage,
    };
};

function RenderImage({ iconType }) {
    return (
        <img className="checkboxIcon" alt="alternative icon" src={iconType} />
    );
}

class CheckBoxField extends Component {
    static propTypes = {
        label: PropTypes.string.isRequired,
        refreshState: PropTypes.func,
    };

    constructor(props) {
        super(props);
        this.state = {
            field: this.props.workflow.form.getField(this.props.label),
            descriptionVisible: false,
            isVisible: false,
        };
    }

    handleChange = (event) => {
        const target = event.target;
        const value = target.checked;
        const field = this.state.field;
        field.value = value;
        if (value) this.toggleElement(false);
        this.setState({
            field: field,
        });
    };

    toggleDescription = (isVisible) => {
        this.setState({
            descriptionVisible: isVisible,
        });
    };
    toggleVisible = (isVisible) => {
        this.setState({
            isVisible: isVisible,
        });
    };

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.refresh !== this.props.refresh) {
            this.toggleDescription(false);
            this.toggleVisible(false);
        }
        if (prevState.isVisible !== this.state.isVisible) {
            this.toggleVisible(this.state.isVisible);
            if (this.state.isVisible) {
                this.toggleDescription(true);
            } else {
                this.toggleDescription(false);
            }
        }
    }

    toggleElement(isRefresh) {
        const { refreshState } = this.props;
        this.toggleVisible(isRefresh);

        if (isRefresh) refreshState();
        if (this.state.isVisible) {
            this.toggleVisible(!this.state.isVisible);
        }
    }

    renderDescription(){
        const { field } = this.state;
        if (field.description){
            return (
                <div className="description-container">
                    <p className="description-text">
                        {interpolateTranslatedText(field.description)}
                    </p>
                </div>
            )
        }
        
    }

    render() {
        const { field } = this.state;
        return (
            <div
                className={`checkbox-container col-12 ${
                    this.state.descriptionVisible ? "" : "description-hidden"
                }`}
            >
                <div className="row">
                    <div className="col-7">
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={field.value}
                                    checkedIcon={
                                        <RenderImage iconType={checkedIcon} />
                                    }
                                    icon={
                                        <RenderImage iconType={unchedkedIcon} />
                                    }
                                    name="test"
                                    color="default"
                                    onChange={this.handleChange}
                                    value={field.value}
                                    className="checkbox-field"
                                />
                            }
                            label={interpolateTranslatedText(field.title)}
                        />
                    </div>
                    <div className="col-5">
                        <div className="control-switch">
                            <div className="row">
                                <div className="col">
                                    <button
                                        onClick={() => this.toggleElement(true)}
                                        className={`${
                                            this.state.descriptionVisible
                                                ? "active"
                                                : ""
                                        }`}
                                    >
                                        <FormattedMessage id="consentForm.show" />
                                    </button>
                                </div>
                                <div className="col">
                                    <button
                                        onClick={() =>
                                            this.toggleElement(false)
                                        }
                                        className={`${
                                            this.state.descriptionVisible
                                                ? ""
                                                : "active"
                                        }`}
                                    >
                                        <FormattedMessage id="consentForm.hide" />
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    {this.renderDescription()}
                </div>
            </div>
        );
    }
}

CheckBoxField = connect(mapStateToProps)(CheckBoxField);
export default CheckBoxField;
