import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import FormTextField from "../../fields/textField/TextField";
import WorkflowState from "../../workflowState/WorfkflowState";
import PrevButton from "../../controlButtons/PrevButton";
import NextButton from "../../controlButtons/NextButton";
import { FormattedMessage } from "react-intl";

const mapStateToProps = (state) => {
    return {
        workflow: state.workflow,
    };
};

class RegistrationForm extends Component {
    static propTypes = {
        form: PropTypes.string.isRequired,
        step: PropTypes.number.isRequired,
    };

    renderInitialSection() {
        return (
            <div className="container d-flex flex-column">
                <div className="m-0">
                    <WorkflowState completed={3} />
                </div>
                <div className="form-container">
                    <PrevButton />
                    <NextButton />
                    <div className="clearfix" />
                    <div className="row">
                        <div className="col">
                            <div className="col-12 head-info">
                                <p className="form-label">
                                    <FormattedMessage id="registrationForm.header" />
                                </p>
                            </div>
                            <div className="col-md-12 form-row justify-content-center">
                                <div className="col-xs-12 col-md-6 col-lg-4">
                                    <FormTextField label="user:firstname" />
                                </div>
                                <div className="col-xs-12 col-md-6 col-lg-4">
                                    <FormTextField label="user:lastname" />
                                </div>
                            </div>
                            <div className="col-md-12 form-row justify-content-center">
                                <div className="col-xs-12 col-md-8 col-lg-6">
                                    <FormTextField label="user:address" />
                                </div>
                                <div className="col-xs-12 col-md-4 col-lg-2">
                                    <FormTextField label="user:unit" />
                                </div>
                            </div>
                            <div className="col-md-12 form-row justify-content-center">
                                <div className="col-xs-12 col-md-12 col-lg-4">
                                    <FormTextField label="user:city" />
                                </div>
                                <div className="col-xs-12 col-md-6 col-lg-2">
                                    <FormTextField
                                        label="user:state"
                                        className="align-middle"
                                    />
                                </div>
                                <div className="col-xs-12 col-md-6 col-lg-2">
                                    <FormTextField
                                        label="user:zipcode"
                                        className="align-middle"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    renderSection() {
        switch (this.props.workflow.form.sectionId) {
            case 0:
                return this.renderInitialSection();
            default:
                console.log("Section not found");
        }
    }

    render() {
        return this.renderSection();
    }
}

RegistrationForm = connect(mapStateToProps)(RegistrationForm);
export default RegistrationForm;
