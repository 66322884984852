import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import InfoField from "../../fields/infoField/InfoField";
import CheckBoxField from "../../fields/checkBoxField/CheckBoxField";
import PrevButton from "../../controlButtons/PrevButton";
import NextButton from "../../controlButtons/NextButton";
import WorkflowState from "../../workflowState/WorfkflowState";
import { FormattedMessage } from "react-intl";

const mapStateToProps = (state) => {
    return {
        workflow: state.workflow,
    };
};

class ConsentForm extends Component {
    static propTypes = {
        form: PropTypes.string.isRequired,
        step: PropTypes.number.isRequired,
    };

    constructor(props) {
        super(props);
        let interpolationData = {};
        interpolationData["user:firstname"] = this.props.workflow.getValueById(
            "user:firstname"
        );
        this.state = {
            current_visible: false,
            interpolationData,
        };
    }

    toggleRefresh = () => {
        this.setState({
            current_visible: !this.state.current_visible,
        });
    };

    renderConsentSection() {
        return (
            <div className="container d-flex flex-column">
                <div className="m-0">
                    <WorkflowState completed={4} />
                </div>
                <div className="form-container">
                    <PrevButton />
                    <NextButton />
                    <div className="row">
                        <div className="col-12 head-info">
                            <p className="form-label">
                                <FormattedMessage id="consentForm.header" />
                            </p>
                        </div>
                        <div className="col-md-12 form-row d-flex justify-content-center">
                            <div className="col-xs-12 col-md-10 col-lg-8">
                                <InfoField
                                    label="dummy:consent:informations"
                                    interpolationData={
                                        this.state.interpolationData
                                    }
                                />
                            </div>
                        </div>
                        <div className="col-md-12 form-row d-flex justify-content-center">
                            <div className="col-xs-12 col-md-10 col-lg-8">
                                <CheckBoxField
                                    label="consent:privacyPolicy"
                                    refresh={this.state.current_visible}
                                    refreshState={() => {
                                        this.toggleRefresh();
                                    }}
                                />
                            </div>
                            <div className="col-xs-12 col-md-10 col-lg-8">
                                <CheckBoxField
                                    label="consent:termsAndConditions"
                                    refresh={this.state.current_visible}
                                    refreshState={() => {
                                        this.toggleRefresh();
                                    }}
                                />
                            </div>
                            <div className="col-xs-12 col-md-10 col-lg-8">
                                <CheckBoxField
                                    label="consent:communications"
                                    refresh={this.state.current_visible}
                                    refreshState={() => {
                                        this.toggleRefresh();
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    renderSection() {
        switch (this.props.workflow.form.sectionId) {
            case 0:
                return this.renderConsentSection();
            default:
                console.log("Section not found");
        }
    }

    render() {
        return this.renderSection();
    }
}

ConsentForm = connect(mapStateToProps)(ConsentForm);
export default ConsentForm;
