import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import moment from "moment";
import { VARIANT } from "../../settings";
import { VARIANT_ONLINE } from "../../constants/settings";
import { FormattedMessage } from "react-intl";
import formLangPicker from "../../helpers/langHelper";

const mapStateToProps = (state) => {
    return {
        workflowState: state.workflowState,
        lang: state.selectedLanguage,
    };
};

class WorkflowState extends Component {
    static propTypes = {
        completed: PropTypes.number.isRequired, // temporary w/o api connection
    };

    renderLocation() {
        const { workflowState } = this.props;
        if (VARIANT === VARIANT_ONLINE) {
            return (
                <div className="row">
                    <div className="col-2 text-right info-type">
                        <FormattedMessage id="workflowStep.where" />
                    </div>
                    <div className="col-10 location-info">
                        <p className="address1">
                            {workflowState.location.name}
                        </p>
                        <p className="address2">
                            {workflowState.location.address}
                        </p>
                    </div>
                </div>
            );
        }
    }

    renderService () {
        const { workflowState } = this.props;

        if (workflowState.service) {
            return (
                <div className="row">
                    <div className="col-2 text-right info-type">
                        <FormattedMessage id="workflowStep.what" />
                    </div>
                    <div className="col-10 info">
                        {
                            formLangPicker(workflowState.service.choices)[
                                workflowState.service.value
                            ]
                        }
                    </div>
                </div>
            )
        }
    }

    render() {
        const { workflowState } = this.props;
        return (
            <div className="workflow-state-container container-fluid">
                {this.renderService()}
                <div className="row">
                    <div className="col-2 text-right info-type">
                        <FormattedMessage id="workflowStep.when" />
                    </div>
                    <div className="col-10 info">
                        {workflowState.datetime
                            ? moment
                                  .parseZone(workflowState.datetime)
                                  .format("MM-DD-YYYY h:mm A")
                            : ""}
                    </div>
                </div>
                {this.renderLocation()}
                <div className="row">
                    <div className="col-2 d-none d-sm-block"></div>
                    <div className="col-12 col-sm-10 pt-2">
                        <WorkflowStep
                            label="service"
                            id={1}
                            completed={this.props.completed}
                        />
                        <WorkflowStep
                            label="location"
                            id={2}
                            completed={this.props.completed}
                        />
                        <WorkflowStep
                            label="time"
                            id={3}
                            completed={this.props.completed}
                        />
                        <WorkflowStep
                            label="register"
                            id={4}
                            completed={this.props.completed}
                        />
                        <WorkflowStep
                            label="confirmed"
                            id={5}
                            completed={this.props.completed}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

const WorkflowStep = function (props) {
    const isStepCompleted = () => {
        return props.id <= props.completed;
    };

    return (
        <div
            className={`workflow-step ${isStepCompleted() ? "completed" : ""}`}
        >
            <div>{props.id}</div>
            <p>
                <FormattedMessage id={`workflowStep.${props.label}`} />
            </p>
        </div>
    );
};

WorkflowState = connect(mapStateToProps)(WorkflowState);
export default WorkflowState;
