import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import { setWorkflowState } from "../../../actions/Workflow";
import formLangPicker from "../../../helpers/langHelper";
import { interpolateTranslatedText } from "../../../helpers/interpolationHelper";

const mapStateToProps = (state) => {
    return {
        workflow: state.workflow,
        date: state.date,
        lang: state.selectedLanguage,
    };
};

const mapActionsToProps = (dispatch) => {
    return {
        setWorkflowState: (state) => dispatch(setWorkflowState(state)),
    };
};

class FormSelectField extends Component {
    static propTypes = {
        label: PropTypes.string.isRequired,
    };

    constructor(props) {
        super(props);
        this.state = {
            field: this.props.workflow.form.getField(this.props.label),
        };
    }

    handleChange = (event) => {
        const target = event.target;
        const value = target.value;
        const field = this.state.field;
        field.value = value;
        if (field.identifier === "appointment:service:type") {
            this.props.setWorkflowState({
                service: {
                    choices: field.choices,
                    value: value,
                },
            });
        } else if (field.identifier === "user:communication:type") {
            const value = (item) => this.props.workflow.form.getField(item);
            if (field.value === 1) {
                value("user:email").required = true;
                value("user:mobilePhone").required = false;
            } else if (field.value === 2) {
                value("user:email").required = false;
                value("user:mobilePhone").required = true;
            } else {
                value("user:email").required = true;
                value("user:mobilePhone").required = true;
            }
        }
        this.setState({
            field: field,
        });
    };

    renderOptions() {
        const { choices } = this.state.field;
        return formLangPicker(choices).map((choice, key) => {
            return (
                <MenuItem key={key} value={key}>
                    {choice}
                </MenuItem>
            );
        });
    }

    render() {
        const { field } = this.state;
        return (
            <FormControl className="select-field">
                <InputLabel>
                    {interpolateTranslatedText(field.title)}
                </InputLabel>
                <Select
                    className="text-left"
                    disableUnderline
                    name={field.identifier}
                    onChange={this.handleChange}
                    error={field.error}
                    value={field.value}
                    variant="standard"
                >
                    {this.renderOptions()}
                </Select>
            </FormControl>
        );
    }
}

FormSelectField = connect(mapStateToProps, mapActionsToProps)(FormSelectField);
export default FormSelectField;
