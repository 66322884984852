import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Map from "./map/Map";

const mapStateToProps = (state) => {
    return {
        workflow: state.workflow,
    };
};

class MapField extends Component {
    static propTypes = {
        label: PropTypes.string.isRequired,
    };

    constructor(props) {
        super(props);
        this.state = {
            field: this.props.workflow.form.getField(this.props.label),
        };
    }

    render() {
        const { field } = this.state;
        return <Map markers={field.value} />;
    }
}

MapField = connect(mapStateToProps)(MapField);
export default MapField;
