import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { workflowNextStep, toggleQr } from "../../../actions/Workflow";

const mapStateToProps = (state) => {
    return {
        workflow: state.workflow,
        date: state.date,
        qrVisibility: state.qrVisibility,
    };
};

const mapActionsToProps = (dispatch) => {
    return {
        workflowNextStep: (workflow) => dispatch(workflowNextStep(workflow)),
        toggleQr: (qrVisibility) => dispatch(toggleQr(qrVisibility)),
    };
};

class QrScannerField extends Component {
    static propTypes = {
        label: PropTypes.string.isRequired,
    };

    constructor(props) {
        super(props);
        this.state = {
            field: this.props.workflow.form.getField(this.props.label),
        };
    }

    prepare() {
        if (window.QRScanner) {
            window.QRScanner.prepare(this.prepareDone); // show the prompt
        }
    }

    prepareDone = (err, status) => {
        if (err) {
            // here we can handle errors and clean up any loose ends.
            console.error(err);
        }
        if (status.authorized) {
            window.QRScanner.show();
            window.QRScanner.scan(this.onScan);
        } else if (status.denied) {
            window.QRScanner.openSettings();
        } else {
            alert("Permission denied");
        }
    };

    scan = () => {
        if (window.QRScanner) {
            this.showScanner();
        }
    };

    onScan = (err, text) => {
        const { field } = this.state;
        if (err) {
            console.log(err);
            // an error occurred, or the scan was canceled (error code `6`)
        } else {
            field.value = text;
            this.props.workflow.form.getSection(1).display = false;
            if (field.triggerNext) {
                this.props.workflowNextStep();
            }
        }
        hideQrScanner();
        this.props.toggleQr();
    };

    showScanner() {
        this.prepare();
        this.props.toggleQr();
        document.body.classList.add("cordova");
        document.querySelector(".workflow-container").classList.add("cordova");
    }

    render() {
        return (
            <div className="scan-your-code-field">
                <div className="button" onClick={this.scan}></div>
                <p className="option-label">QR code</p>
            </div>
        );
    }
}

QrScannerField = connect(mapStateToProps, mapActionsToProps)(QrScannerField);
export default QrScannerField;

export function hideQrScanner() {
    window.QRScanner.destroy();
    document.body.classList.remove("cordova");
    document.querySelector(".workflow-container").classList.remove("cordova");
}
