import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
    workflowNextStep,
    setWorkflowState,
} from "../../../../actions/Workflow";
import { GoogleMap, LoadScript, InfoBox, Marker } from "@react-google-maps/api";
import moment from "moment";
import MarkerIco from "./marker-ico.png";

const mapStateToProps = (state) => {
    return {
        workflow: state.workflow,
    };
};

const mapActionsToProps = (dispatch) => {
    return {
        workflowNextStep: (workflow) => dispatch(workflowNextStep(workflow)),
        setWorkflowState: (state) => dispatch(setWorkflowState(state)),
    };
};

class Map extends Component {
    static propTypes = {
        markers: PropTypes.array.isRequired,
    };

    constructor(props) {
        super(props);
        this.state = {
            markers: props.markers,
        };
    }

    renderInfoBox(marker) {
        if (!marker.showWindow) {
            return null;
        }
        return (
            <InfoBox
                onCloseClick={() => {
                    this.onCloseInfoBox(marker);
                }}
                onDomReady={() => {
                    let practice = document.getElementById(marker.uuid);

                    practice.addEventListener("click", () => {
                        this.props.workflow.form.getField(
                            "appointment:location"
                        ).value = marker.uuid;
                        this.props.setWorkflowState({ location: marker });
                        this.props.workflowNextStep();
                    });
                }}
            >
                <div id={marker.uuid} style={{ backgroundColor: `#7abd31` }}>
                    <div
                        style={{
                            fontSize: `12px`,
                            color: `white`,
                            padding: `5px`,
                            width: `150px`,
                        }}
                    >
                        <span>{marker.name}</span>
                        <br />
                        <span>{marker.address}</span>
                    </div>
                    <div
                        style={{
                            backgroundColor: `#fff`,
                            padding: `5px`,
                            border: `1px solid #7abd31`,
                            color: `#7abd31`,
                        }}
                    >
                        Open on {moment().format("ddd")}
                    </div>
                </div>
            </InfoBox>
        );
    }

    onMarkerClick = (marker) => {
        marker.showWindow = true;

        this.setState({
            markers: this.state.markers,
        });
    };

    onCloseInfoBox = (marker) => {
        marker.showWindow = false;
        this.setState({
            markers: this.state.markers,
        });
    };

    renderMarkers() {
        return this.props.markers.map((marker) => {
            return (
                <Marker
                    key={marker.uuid}
                    marker={marker}
                    position={{ lat: marker.lat, lng: marker.lng }}
                    onClick={() => this.onMarkerClick(marker)}
                    options={{ icon: `${MarkerIco}` }}
                >
                    {this.renderInfoBox(marker)}
                </Marker>
            );
        });
    }

    render() {
        const containerStyle = {
            width: "calc(100%)",
            maxWidth: "573px",
            borderRadius: "20px",
            height: "calc(100% - 30px)",
            margin: "0 auto",
        };
        const { markers } = this.props;
        return (
            <LoadScript googleMapsApiKey="AIzaSyA-dzdBfU5uHYRI2crCSsiAslLMJAFayoQ">
                <GoogleMap
                    mapContainerStyle={containerStyle}
                    center={{
                        lat: markers[0].lat,
                        lng: markers[0].lng,
                    }}
                    zoom={10}
                >
                    <>{this.renderMarkers()}</>
                </GoogleMap>
            </LoadScript>
        );
    }
}

Map = connect(mapStateToProps, mapActionsToProps)(Map);
export default React.memo(Map);
