import React, { Component } from "react";
import { hideQrScanner } from "./QrScannerField";
import { connect } from "react-redux";
import { toggleQr } from "../../../actions/Workflow";

const mapStateToProps = (state) => {
    return {
        qrVisibility: state.qrVisibility,
    };
};

const mapActionsToProps = (dispatch) => {
    return {
        toggleQr: (qrVisibility) => dispatch(toggleQr(qrVisibility)),
    };
};

class QrOverlay extends Component {
    hide() {
        hideQrScanner();
        this.props.toggleQr();
    }

    render() {
        const { qrVisibility } = this.props;
        return (
            <>
                {qrVisibility && (
                    <div>
                        <div id="qr-overlay">
                            <div id="qr-scaner">
                                <span id="qr-text">QR Code</span>
                            </div>
                        </div>
                        <div id="qr-close" onClick={() => this.hide()}></div>
                    </div>
                )}
            </>
        );
    }
}

QrOverlay = connect(mapStateToProps, mapActionsToProps)(QrOverlay);
export default QrOverlay;
