import React, { Component } from "react";
import Menu from "./Menu";
import { VARIANT } from "../../settings";
import { VARIANT_ONLINE } from "../../constants/settings";
import { FormattedMessage } from "react-intl";
import LanguageSelect from "../../containers/languageSelect/languageSelect";

class Nav extends Component {
    renderNavButtons = () => {
        return (
            <div className="container-fluid mb-2">
                <div className="row">
                    <div className="col">
                        <div className="nav-item login-btn">
                            <FormattedMessage id="buttons.login" />
                        </div>
                        <div className="nav-item">
                            <div className="float-left">
                                <strong>-A</strong>
                            </div>
                            <div className="float-left pl-2">
                                <strong>A+</strong>
                            </div>
                            <LanguageSelect />
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    renderSearchBar = () => {
        return <div className="search-bar"></div>;
    };

    renderMenuItems = () => {
        return null;
    };
    render() {
        if (VARIANT === VARIANT_ONLINE) {
            return (
                <nav>
                    {this.renderNavButtons()}
                    <Menu />
                    {this.renderSearchBar()}
                </nav>
            );
        }
        return null;
    }
}

export default Nav;
