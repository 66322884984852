import React, { Component } from "react";
import PropTypes from "prop-types";
import Slot from "../slot/Slot";
import moment from "moment";
import { FormattedMessage } from "react-intl";

export class TimeSlots extends Component {
    static propTypes = {
        timeSlots: PropTypes.array.isRequired,
        onChange: PropTypes.func.isRequired,
        dayPhase: PropTypes.string.isRequired,
        timeRange: PropTypes.string.isRequired,
        chosenTimeSlot: PropTypes.any.isRequired,
        refreshState: PropTypes.func.isRequired,
    };

    constructor(props) {
        super(props);

        this.state = {
            itemsToShow: 5,
            isOpen: false,
        };
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.refresh !== this.props.refresh) {
            this.toggleOpen(false);
        }
        if (prevState.isOpen !== this.state.isOpen) {
            this.toggleOpen(this.state.isOpen);
        }
    }

    renderTimeSlot = () => {
        return this.getRenderedItems().map((item, index) => {
            const active = () => {
                if (this.props.chosenTimeSlot) {
                    return (
                        this.props.chosenTimeSlot &&
                        this.props.chosenTimeSlot.start_date_time ===
                            item.start_date_time
                    );
                } else return false;
            };
            return (
                <Slot
                    key={index}
                    date={moment
                        .parseZone(item.start_date_time)
                        .format("h:mm A")}
                    active={active()}
                    handleClick={() => {
                        this.props.onChange(item);
                    }}
                />
            );
        });
    };

    toggleOpen = (isOpen) => {
        this.setState({
            isOpen: isOpen,
        });
    };
    toggleMoreLessButton(isOpen) {
        const { refreshState } = this.props;
        this.toggleOpen(isOpen);

        if (isOpen) refreshState();
        if (this.state.isOpen) {
            this.toggleOpen(!this.state.isOpen);
        }
    }

    getRenderedItems = () => {
        if (this.state.isOpen) {
            return this.props.timeSlots;
        }

        return this.props.timeSlots.slice(0, this.state.itemsToShow);
    };

    renderHeader = () => {
        const { timeSlots, dayPhase, timeRange } = this.props;
        let header;
        if (timeSlots.length === 0) {
            header = (
                <FormattedMessage
                    id="timeSlot.header"
                    values={{
                        dayPhase: (
                            <FormattedMessage
                                id={`timeSlot.${dayPhase}`}
                                values={{ timeRange: "" }}
                            />
                        ),
                    }}
                />
            );
        } else {
            header = (
                <FormattedMessage
                    id={`timeSlot.${dayPhase}`}
                    values={{ timeRange }}
                />
            );
        }

        return <div className="timeSlotsHeader">{header}</div>;
    };

    renderMoreLessButton = () => {
        const timeSlotsLength = this.props.timeSlots.length;

        if (timeSlotsLength > 0) {
            if (timeSlotsLength > this.state.itemsToShow) {
                return this.state.isOpen ? (
                    <FormattedMessage id="buttons.less" />
                ) : (
                    <FormattedMessage id="buttons.more" />
                );
            }
        }
    };

    render() {
        return (
            <div className="timeSlotsContainer">
                <div className="container">
                    <div>
                        <div className="headerContainer">
                            {this.renderHeader()}
                        </div>
                        <div className="timeSlotsWrapper">
                            <div className="timeSlots">
                                {this.renderTimeSlot()}
                            </div>
                            <div className="moreLessButtonContainer">
                                <div
                                    className="moreLessButton"
                                    onClick={() =>
                                        this.toggleMoreLessButton(
                                            !this.state.isOpen
                                        )
                                    }
                                >
                                    {this.renderMoreLessButton()}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default TimeSlots;
