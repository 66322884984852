import React, { Component } from "react";
import { BrowserRouter, HashRouter} from "react-router-dom";

class RouterContainer extends Component {
    render() {
        if (window.cordova) {
            return (
                <HashRouter >
                    {this.props.children}
                </HashRouter>
            )
        } else {
            return (
                <BrowserRouter basename={process.env.REACT_APP_BASE_PATH}>
                    {this.props.children}
                </BrowserRouter>
            )
        }
    }
}

export default RouterContainer;
