import React, { Component } from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import { initWorkflow } from "../../../../actions/Workflow";
import YelpImg from "./img/yelp_logo.png";
import GoogleImg from "./img/google_logo.png";

const mapStateToProps = (state) => {
    return {
        workflowState: state.workflowState,
        workflow: state.workflow,
    };
};

const mapActionsToProps = (dispatch) => {
    return {
        workflowInit: (workflow) => dispatch(initWorkflow(workflow)),
    };
};

class ConfirmationSurveyForm extends Component {
    static propTypes = {
        form: PropTypes.string.isRequired,
        step: PropTypes.number.isRequired,
    };

    constructor(props) {
        super(props);
        this.state = {
            field: this.props.workflow.form.getField(
                "user:service:satisfaction"
            ),
        };
    }
    render() {
        const { field } = this.state;
        return (
            <div className="container d-flex flex-column">
                <div className="survey-container">
                    <div className="clearfix" />

                    <div className="row">
                        <div className="col">
                            <div className="col-12">
                                <div className="current_place">
                                    <div className="first_dot"></div>
                                    <div className="first_dot"></div>
                                </div>
                                <div className="survey_feedback">
                                    {field.value >= 75 ? (
                                        <div>
                                            <p>
                                                <FormattedMessage id="satisfaction.feedbackGreatv2" />
                                            </p>
                                            <p>
                                                <FormattedMessage id="satisfaction.feedbackGreatv1" />
                                            </p>
                                            <div className="imgs">
                                                <div className="first_img">
                                                    <a href=".">
                                                        <img src={YelpImg} alt="Yelp" />
                                                    </a>
                                                </div>
                                                <div className="second_img">
                                                    <a href=".">
                                                        <img src={GoogleImg} alt="Google" />
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    ) : (
                                        <p>
                                            <FormattedMessage id="satisfaction.feedbackPoorly" />
                                        </p>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default connect(
    mapStateToProps,
    mapActionsToProps
)(ConfirmationSurveyForm);
