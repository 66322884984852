import React, { Component } from "react";
import { connect } from "react-redux";
import { initWorkflow, workflowNextStep } from "../../actions/Workflow";
import { LoginService, WorkflowService } from "../../services/Resources";
import Loader from "../../components/loader/Loader";
import { saveAccessToken } from "../../actions/auth.actions";
import SurveyForm from "../../components/forms/survey/survey/Form";
import ConfirmationSurveyForm from "../../components/forms/survey/confirmationSurvey/Form";
import { WorkflowSurveyTokenService } from "../../services/Resources";
import ErrorPage from "../../components/template/ErrorPage";

const mapStateToProps = (state) => {
    return {
        workflow: state.workflow,
        date: state.date, // temporary to handle store update
    };
};

const mapActionsToProps = (dispatch) => {
    return {
        saveToken: (payload) => dispatch(saveAccessToken(payload)),
        workflowInit: (workflow) => dispatch(initWorkflow(workflow)),
        workflowNextStep: (workflow) => dispatch(workflowNextStep(workflow)),
    };
};

class WorkflowSatisfactionSurvey extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isError: {},
        };

    }
    componentDidMount() {
        this.createSchelduiling();
        window.addEventListener("reload", this.createSchelduiling);
    }
    createSchelduiling = (response) => {
        const client_id = process.env.REACT_APP_ONLINE_CLIENT_ID;
        const client_secret = process.env.REACT_APP_ONLINE_CLIENT_SECRET;

        const formData = {
            grant_type: "client_credentials",
            client_id,
            client_secret,
        };

        const data = Object.keys(formData)
            .map((key) => `${key}=${encodeURIComponent(formData[key])}`)
            .join("&");

        LoginService.post(
            {
                params: {
                    enabled: undefined,
                },
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                },
                data,
            },
            this.onAuthenticationSuccess
        );
    };

    onAuthenticationSuccess = (data) => {
        this.props.saveToken(data);
        WorkflowSurveyTokenService.get(
            {
                params: {
                    key: this.props.secureToken,
                },
            },
            this.initializeWorkflow,
            this.onError
        );
    };

    initializeWorkflow = (response) => {
        WorkflowService.post(
            {
                params: {
                    id: response.uuid,
                    action: "initialize",
                },
            },
            this.loadWorkflow
        );
    };

    onError = (error) => {
        this.setState({
            isError: error,
        });
    };

    loadWorkflow = (response) => {
        this.props.workflowInit(response);
    };

    renderStep = () => {
        const { workflow } = this.props;
        if (workflow.displayStep) {
            return <div>{this.renderForm()}</div>;
        }
    };

    renderForm = () => {
        const { workflow } = this.props;
        const { uuid, sectionId } = workflow.form;
        switch (workflow.form.identifier) {
            case "survey":
                return <SurveyForm form={uuid} step={sectionId} />;
            case "survey_confirmation":
                return <ConfirmationSurveyForm form={uuid} step={sectionId} />;
            default:
                return (
                    <div>
                        <div>Form {workflow.form.identifier}</div>
                    </div>
                );
        }
    };

    renderView = () => {
        const { workflow } = this.props;
        if (this.state.isError.status) {
            return <ErrorPage isError={this.state.isError} />;
        } else if (!workflow && !this.state.isError.status) {
            return <Loader fixed={true} />;
        } else {
            return this.renderStep();
        }
    };

    render() {
        return <div className="workflow-container">{this.renderView()}</div>;
    }
}

WorkflowSatisfactionSurvey = connect(
    mapStateToProps,
    mapActionsToProps
)(WorkflowSatisfactionSurvey);
export default WorkflowSatisfactionSurvey;
