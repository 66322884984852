import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import FormTextField from "../../../fields/textField/TextField";
import FormDateField from "../../../fields/dateField/DateField";
import PostalCodeField from "../../../fields/postalCodeField/PostalCodeField";
import SelectField from "../../../fields/selectField/SelectField";
import IHaveNoIdField from "../../../fields/iHaveNoIdField/IHaveNoIdField";
import { workflowNextStep } from "../../../../actions/Workflow";
import PrevButton from "../../../controlButtons/PrevButton";
import NextButton from "../../../controlButtons/NextButton";
import QrScannerField from "../../../fields/qrScanner/QrScannerField";
import { FormattedMessage } from "react-intl";
import IdScanner from "../../../fields/scanId/IdScanner";

const mapStateToProps = (state) => {
    return {
        workflow: state.workflow,
    };
};

const mapActionsToProps = (dispatch) => {
    return {
        workflowNextStep: (workflow) => dispatch(workflowNextStep(workflow)),
    };
};

class LookupForm extends Component {
    static propTypes = {
        form: PropTypes.string.isRequired,
        step: PropTypes.number.isRequired,
    };

    goNext = () => {
        this.props.workflowNextStep();
    };

    renderInitialSection() {
        return (
            <div className="container lookup">
                <div className="lookup-background"></div>

                <div className="form-lookup">
                    <div className="row">
                        <div className="col-12 head-info-lookup">
                            <div className="head-title-lookup">
                                <FormattedMessage id="lookupForm.welcome" />
                            </div>
                            <div className="subtitle-lookup">
                                <FormattedMessage id="lookupForm.info" />
                            </div>
                        </div>
                        <div className="col-xs-12 col-md-5 d-flex justify-content-center">
                            <IdScanner label="dummy:scan:id" />
                        </div>
                        <div className="col-xs-12 col-md-2 d-flex justify-content-center">
                            <QrScannerField label="appointment:code" />
                        </div>
                        <div className="col-xs-12 col-md-5 d-flex justify-content-center">
                            <IHaveNoIdField onClick={this.goNext} />
                        </div>
                    </div>
                    <div className="logo-lookup"></div>
                </div>
            </div>
        );
    }

    renderPersonalSection() {
        return (
            <div className="container">
                <div className="form-container">
                    <PrevButton />
                    <NextButton />
                    <div className="clearfix" />
                    <div className="row">
                        <div className="col-12 head-info">
                            <p className="form-label">
                                <FormattedMessage id="lookupForm.more" />
                            </p>
                        </div>
                        <div className="col-md-12 form-row justify-content-center">
                            <div className="col-xs-12 col-md-6 col-lg-4">
                                <FormTextField label="user:firstname" />
                            </div>
                            <div className="col-xs-12 col-md-6 col-lg-4">
                                <FormTextField label="user:lastname" />
                            </div>
                        </div>
                        <div className="col-md-12 form-row justify-content-center">
                            <div className="col-xs-12 col-md-6 col-lg-4">
                                <PostalCodeField label="user:zipcode" />
                            </div>
                            <div className="col-xs-12 col-md-6 col-lg-4">
                                <FormDateField label="user:dob" />
                            </div>
                        </div>
                        <div className="col-md-12 form-row justify-content-center">
                            <div className="col-xs-12 col-md-6 col-lg-4 no-title">
                                <SelectField label="user:identity" />
                            </div>
                            <div className="col-xs-12 col-md-6 col-lg-4">
                                <SelectField label="appointment:service:type" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    renderSection() {
        switch (this.props.workflow.form.sectionId) {
            case 0:
                return this.renderInitialSection();
            case 1:
                return this.renderPersonalSection();
            default:
                console.log("Section not found");
        }
    }

    render() {
        return this.renderSection();
    }
}

LookupForm = connect(mapStateToProps, mapActionsToProps)(LookupForm);
export default LookupForm;
