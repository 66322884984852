import { LOCALES } from "../constants";

export const English = {
    [LOCALES.ENGLISH]: {
        buttons: {
            login: "Login",
            logout: "Logout",
            close: "Close",
            more: "more ▼",
            less: "less ▲",
            locate: "Locate Me",
            go: "Go",
        },
        kiosk: {
            username: "Username",
            password: "Password",
            new: "Create New Account",
            forgot: "Forgot password?",
            searchInput: "Search...",
            popup: {
                timeout: "Timeout in",
                seconds: "Seconds",
                continue: "Continue",
            },
        },
        errors: {
            usernameError: "Invalid Username",
            usernameEmpty: "Username Required",
            passwordEmpty: "Password Required",
            popup: {
                loginFailedTitle: "Login Failed",
                loginFailed: "Please enter a correct username and password. ",
            },
        },
        nav: {
            language: {
                french: "Français",
                german: "Deutsche",
                spanish: "Española",
                english: "English",
                polish: "Polski",
                japanese: "日本語",
            },
        },
        menu: {
            patients: "Patients and {br}Individuals",
            healthcare: "Healthcare Providers{br} and Hospitals",
            corpoClient: "Corporate {br}Clients",
            insurance: "Insurance {br}Professionals",
        },
        satisfaction: {
            header: "Did we make you happy?",
            feedbackGreatv1:
                "We would appreciate it if you took a moment to share your experience on social media. ",
            feedbackGreatv2:
                "This feedback helps us improve our ability to provide you with the best care possible.",
            feedbackPoorly: "Thank you for giving us your feedback",
            submit: "Submit",
        },
        errorPage: {
            notFound: "Sorry, we couldn't find the page you were looking for.",
            another:
                "It looks like something went wrong, please try again later or contact us.",
        },
        service: {
            title: "Canada’s Healthcare Diagnostics & Testing Company",
            info: "Labs & Appointments",
            description:
                "Find locations nearby , schedule and no wait appointments",
        },
        location: {
            notFound: "Location not found",
        },
        locationField: {
            openingHours: "Opens at 8:00am",
            openingClosingHours: "Open between 8:00am - 8:00pm",
            scheduler: "Click to Schedule",
            more: "More",
        },
        lookupForm: {
            welcome: "Welcome",
            info: "tap one to check-in",
            more: "Tell us a little about you",
        },
        registrationForm: {
            header: "Tap the forward arrow if this looks right",
        },
        contactForm: {
            header: "How we should contact you?",
        },
        confirmationForm: {
            kiosk: {
                header: "You're checked in!",
                content: "Take a seat and we will call you shortly.",
            },
            online: {
                header: "Your appointment has been scheduled",
                qrCode: "Your QR Code is:",
            },
        },
        consentForm: {
            header: "Consent & Notice",
            show: "SHOW",
            hide: "HIDE",
        },
        workflowStep: {
            what: "what",
            when: "when",
            where: "where",
            service: "Service",
            location: "Location",
            time: "Time",
            register: "Register",
            confirmed: "Confirmed",
        },
        timeSlot: {
            header:
                "{dayPhase} - for a {dayPhase} appointment, choose future date",
            morning: "Morning {timeRange}",
            afternoon: "Afternoon {timeRange}",
            evening: "Evening {timeRange}",
        },
        daySlots: {
            Today: "Today",
            Monday: "Tuesday",
            Tuesday: "Tuesday",
            Wednesday: "Wednesday",
            Thursday: "Thursday",
            Friday: "Friday",
            Saturday: "Saturday",
            Sunday: "Sunday",
            Beyond: "Beyond",
        },
        idField: {
            scanId: {
                scan: "Scan ID",
                unable: "Unable to scan your document",
            },
            noId: "No ID",
        },
    },
};
