import React, { useState } from "react";
import ButtonImg from "./img/settingsButton.svg";
import AppSettings from "../../../containers/appSettings/AppSettings";

const SettingsButton = (props) => {
    const [isOpen, setIsOpen] = useState(false);

    return (
        <div className="settingsButton" onClick={props.openSettings}>
            {props.currentlyOpen === "settings" && (
                <AppSettings setIsOpen={setIsOpen} />
            )}
            <img
                className="settingsIcon"
                onClick={() => setIsOpen(!isOpen)}
                alt="settings"
                src={ButtonImg}
            />
        </div>
    );
};

export default SettingsButton;
