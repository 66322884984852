import React, { Component } from "react";
import { connect } from "react-redux";
import { workflowNextStep } from "../../actions/Workflow";
import forward from "./img/Forward-Btn.png";

const mapStateToProps = (state) => {
    return {
        workflow: state.workflow,
    };
};

const mapActionsToProps = (dispatch) => {
    return {
        workflowNextStep: (workflow) => dispatch(workflowNextStep(workflow)),
    };
};

class NextButton extends Component {
    render() {
        return (
            <div
                className="control-button control-right"
                onClick={this.props.workflowNextStep}
            >
                <img alt="Forward Btn" src={forward} />
            </div>
        );
    }
}

NextButton = connect(mapStateToProps, mapActionsToProps)(NextButton);
export default NextButton;
