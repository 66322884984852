import React, { Component } from "react";
import PropTypes from "prop-types";
import Confetti from "react-confetti";
import { FormattedMessage } from "react-intl";
import { reload } from "../../../../helpers/reload";

class ConfirmationForm extends Component {
    static propTypes = {
        form: PropTypes.string.isRequired,
        step: PropTypes.number.isRequired,
    };

    constructor(props) {
        super(props);
        this.state = {
            confetti: true,
        };
    }

    componentDidMount() {
        setTimeout(() => {
            this.setState({ confetti: false });
        }, 4900);
        setTimeout(() => {
            reload();
        }, 10000);
    }

    renderConfetti() {
        const { confetti } = this.state;
        if (confetti) {
            return (
                <Confetti
                    width={window.innerWidth}
                    height={window.innerHeight}
                />
            );
        }
    }

    render() {
        return (
            <div className="container">
                {this.renderConfetti()}
                <div className="form-container">
                    {this.renderConfetti()}
                    <div className="clearfix" />
                    <div className="row align-items-center">
                        <div className="col checked-in">
                            <div>
                                <p>
                                    <FormattedMessage id="confirmationForm.kiosk.header" />
                                </p>
                                <p>
                                    <FormattedMessage id="confirmationForm.kiosk.content" />
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default ConfirmationForm;
