import React, { Component } from "react";
import PropTypes from "prop-types";
import Slot from "../slot/Slot";
import moment from "moment";
import BeyondDatePicker from "./BeyondDatePicker";

export class DaySlots extends Component {
    static propTypes = {
        onDateChange: PropTypes.func.isRequired,
        slots: PropTypes.array.isRequired,
    };

    constructor(props) {
        super(props);

        this.state = {
            isOpenDatePicker: false,
            date: undefined,
            beyondIndex: this.props.slots.length + 1,
            active: 0,
        };
    }

    toggleDatePicker = () => {
        this.setState({
            isOpenDatePicker: !this.state.isOpenDatePicker,
        });
    };

    closeDatePicker = (childDate) => {
        this.setState({ date: moment(childDate) }, () => {
            this.toggleDatePicker();
            this.activeDatePickerSlot();
        });
    };

    setActive = (index, slot) => {
        this.props.onDateChange(slot);

        this.setState({
            active: index,
        });
    };

    getSlotText = (slot) => {
        const today = moment().format("DD-MM-YYYY");
        if (today === slot.format("DD-MM-YYYY")) {
            return "Today";
        } else {
            return slot.format("dddd");
        }
    };

    // getSlotText = (slot) => {
    //     const today = moment().format("DD-MM-YYYY");
    //     if (today === slot.format("DD-MM-YYYY")) {
    //         return <FormattedMessage id="daySlots.Today" />;
    //     } else {
    //         return <FormattedMessage id={`daySlots.${slot.format("dddd")}`} />;
    //     }
    // };

    renderSlots = () => {
        return this.props.slots.map((slot, index) => {
            return (
                <Slot
                    key={index}
                    text={this.getSlotText(slot)}
                    active={this.state.active === index}
                    handleClick={() => {
                        this.setActive(index, slot);
                    }}
                />
            );
        });
    };

    activeDatePickerSlot = () => {
        const { beyondIndex, date } = this.state;
        this.setActive(beyondIndex, date);
    };

    render() {
        const { active, date, isOpenDatePicker, beyondIndex } = this.state;
        return (
            <div className="daySlotsContainer">
                <div className="container">
                    <div className="daySlotsWrapper">
                        <div className="daySlots">
                            {this.renderSlots()}
                            {isOpenDatePicker && (
                                <BeyondDatePicker
                                    date={this.closeDatePicker}
                                    cancel={this.toggleDatePicker}
                                />
                            )}
                            {/* <Slot
                                key="beyond"
                                text={
                                    date
                                        ? date.format("MM-DD-yyyy").toString()
                                        : "Beyond"
                                }
                                active={active === beyondIndex}
                                handleClick={this.toggleDatePicker}
                            /> */}
                            <Slot
                                key="beyond"
                                date={
                                    date
                                        ? date.format("MM-DD-yyyy").toString()
                                        : null
                                }
                                text="Beyond"
                                active={active === beyondIndex}
                                handleClick={this.toggleDatePicker}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default DaySlots;
