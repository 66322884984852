import React, { useEffect } from "react";
import { connect } from "react-redux";
import Template from "./components/template/Template";
import { setTokens } from "./actions/auth.actions";
import WorkflowOnline from "./containers/workflowOnline/WorkflowOnline";
import WorkflowKiosk from "./containers/workflowKiosk/WorkflowKiosk";
import QrOverlay from "./components/fields/qrScanner/qrOverlay";
import LoginComponent from "./components/login/LoginComponent";
import { VARIANT_APP } from "./constants/settings";
import { VARIANT } from "./settings";
import { createMeter } from "./configCmeter/configMeter";
import { setCmeter } from "./actions/cmeter.actions";

const mapStateToProps = (state) => {
    return {
        isLogin: state.isLogin,
    };
};
const mapActionsToProps = (dispatch) => {
    return {
        login: (payload) => dispatch(setTokens(payload)),
        saveCmeter: (payload) => dispatch(setCmeter(payload)),
    };
};

function App(props) {
    const { isLogin } = props;
    const access_token = localStorage.getItem("access");
    const refresh_token = localStorage.getItem("refresh");

    if (access_token && refresh_token && VARIANT === VARIANT_APP) {
        props.login({ access_token, refresh_token });
    }
    useEffect(() => {
        const cmeterInstance = createMeter();
        cmeterInstance.onStatusUpdate = (status) => {
            props.saveCmeter(status);
        };
        cmeterInstance.start();

        setInterval(() => {
            if (!cmeterInstance.isBusy()) {
                cmeterInstance.start();
            }
        }, 60000);
    }, []); // eslint-disable-line

    return (
        <Template>
            {VARIANT === VARIANT_APP ? (
                isLogin ? (
                    <WorkflowKiosk />
                ) : (
                    <LoginComponent />
                )
            ) : (
                <WorkflowOnline />
            )}
            <QrOverlay />
        </Template>
    );
}

export default connect(mapStateToProps, mapActionsToProps)(App);
