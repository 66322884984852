import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { workflowNextStep } from "../../../actions/Workflow";
import { Modal, Button } from "react-bootstrap";
import moment from "moment";
import { FormattedMessage } from "react-intl";

const mapStateToProps = (state) => {
    return {
        workflow: state.workflow,
    };
};

const mapActionsToProps = (dispatch) => {
    return {
        workflowNextStep: (workflow) => dispatch(workflowNextStep(workflow)),
    };
};

class BlinkIdScanner extends Component {
    static propTypes = {
        onScan: PropTypes.func.isRequired,
        onError: PropTypes.func.isRequired,
        onClose: PropTypes.func.isRequired,
    };

    componentDidMount() {
        this.initializeScanner();
    }

    scanSuccess = (data) => {
        if (data.returnValue) {
            this.processResults(data.detail.recognizer);
        } else {
            this.props.onError();
        }
    };

    processResults = (results) => {
        const firstName = results.firstName.split(" ")[0];
        const lastName = results.lastName;
        const dob = moment(
            results.dateOfBirth.day +
            "-" +
            results.dateOfBirth.month +
            "-" +
            results.dateOfBirth.year,
            "DD-MM-YYYY"
        ).format("DD-MM-YYYY")
        this.props.onScan({
            firstName, lastName, dob
        })
    };

    initializeScanner = () => {
        const blinkId = document.querySelector("blinkid-in-browser");

        blinkId.addEventListener("fatalError", this.props.onError);

        blinkId.addEventListener("scanError", this.props.onError);

        blinkId.addEventListener("scanSuccess", this.scanSuccess);
    };

    renderModal = () => {
        return (
            <Modal show={true} onHide={this.props.onClose}>
                <Modal.Header closeButton></Modal.Header>
                <Modal.Body>
                    <blinkid-in-browser
                        engine-location={
                            window.location.origin +
                            "/js/blinkid/resources/"
                        }
                        license-key={
                            process.env.REACT_APP_MICROBLINK_LICENSE
                        }
                        recognizers="BlinkIdRecognizer"
                    ></blinkid-in-browser>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={this.props.onClose}>
                        <FormattedMessage id="buttons.close" />
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    };

    render() {
        return this.renderModal();
    }
}

export default connect(mapStateToProps, mapActionsToProps)(BlinkIdScanner);
