import React, { Component } from "react";
import PropTypes from "prop-types";
import "date-fns";
import { connect } from "react-redux";
import DateFnsUtils from "@material-ui/lab/AdapterDateFns";
import moment from "moment";
import LocalizationProvider from "@material-ui/lab/LocalizationProvider";
import { interpolateTranslatedText } from "../../../helpers/interpolationHelper";
import { TextField } from "@material-ui/core";
import DatePicker from "@material-ui/lab/DatePicker";

const mapStateToProps = (state) => {
    return {
        workflow: state.workflow,
        date: state.date,
        lang: state.selectedLanguage,
    };
};

class FormDateField extends Component {
    static propTypes = {
        label: PropTypes.string.isRequired,
    };

    constructor(props) {
        super(props);
        const field = this.props.workflow.form.getField(this.props.label);
        this.state = {
            field: field,
            value: field.value ? moment(field.value, "DD-MM-YYYY") : null,
        };
    }

    handleChange = (value) => {
        const field = this.state.field;
        field.value = moment(value).format("DD-MM-YYYY");
        field.error = !value;
        this.setState({
            field: field,
            value: value,
        });
    };

    initialDate = () => {
        if (!this.state.value) {
            this.setState({
                value: moment(
                    moment() + moment.duration(this.state.field.date_offset)
                ).toLocaleString(),
            });
        } else return;
    };

    render() {
        const { field } = this.state;
        return (
            <LocalizationProvider dateAdapter={DateFnsUtils}>
                <DatePicker
                    onOpen={this.initialDate}
                    openTo="year"
                    value={this.state.value}
                    onChange={this.handleChange}
                    inputFormat="yyyy/MM/dd"
                    mask="____/__/__"
                    onError={(error) => console.log(error)}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            className="text-field"
                            label={interpolateTranslatedText(field.title)}
                            id="standard-basic"
                            helperText="yyyy/mm/dd"
                            variant="standard"
                            name={field.identifier}
                            error={field.error}
                        />
                    )}
                />
            </LocalizationProvider>
        );
    }
}

FormDateField = connect(mapStateToProps)(FormDateField);
export default FormDateField;
