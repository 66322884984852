import { LOCALES } from "../constants";

export const German = {
    [LOCALES.GERMAN]: {
        buttons: {
            login: "Anmeldung",
            logout: "Ausloggen",
            close: "Schließen",
            more: "Mehr ▼",
            less: "Weniger ▲",
            locate: "Lokalisiere mich",
            go: "Gehen",
        },
        kiosk: {
            username: "Nutzername",
            password: "Passwort",
            new: "Neuen Account erstellen",
            forgot: "Passwort vergessen?",
            searchInput: "Suche...",
            popup: {
                timeout: "Auszeit in",
                seconds: "Sekunden",
                continue: "Fortsetzen",
            },
        },
        errors: {
            usernameError: "Ungültiger Benutzername",
            usernameEmpty: "Benutzername erforderlich",
            passwordEmpty: "Passwort erforderlich",
            popup: {
                loginFailedTitle: "Anmeldung fehlgeschlagen",
                loginFailed:
                    "Bitte geben Sie einen korrekten Benutzernamen und ein korrektes Passwort ein. ",
            },
        },
        nav: {
            language: {
                french: "Français",
                german: "Deutsche",
                spanish: "Española",
                english: "English",
                polish: "Polski",
                japanese: "日本語",
            },
        },
        menu: {
            patients: "Patienten und{br} Einzelpersonen",
            healthcare: "Gesundheitsdienstleister{br} und Krankenhäuser",
            corpoClient: "Firmenkunden",
            insurance: "Versicherungsprofis",
        },
        satisfaction: {
            header: "Haben wir Sie glücklich gemacht?",
            feedbackGreatv1:
                "Wir würden uns freuen, wenn Sie sich einen Moment Zeit nehmen würden, um Ihre Erfahrungen in den sozialen Medien zu teilen.",
            feedbackGreatv2:
                "Dieses Feedback hilft uns, unsere Fähigkeit zu verbessern, Ihnen die bestmögliche Versorgung zu bieten.",
            feedbackPoorly: "Vielen Dank, dass Sie uns Ihr Feedback gegeben haben",
            submit: "Senden",
        },
        errorPage: {
            notFound:
                "Entschuldigung, wir konnten die gesuchte Seite nicht finden.",
            another:
                "Anscheinend ist etwas schief gelaufen, bitte versuchen Sie es später erneut oder kontaktieren Sie uns. ",
        },
        service: {
            title: "Kanadas Gesundheitsdiagnostik- und Testunternehmen",
            info: "Labore & Termine",
            description:
                "Finden Sie Orte in der Nähe, planen Sie Termine und warten Sie nicht",
        },
        location: {
            notFound: "Ort nicht gefunden",
        },
        locationField: {
            openingHours: "Öffnet um 8:00 Uhr",
            openingClosingHours: "Geöffnet von 8.00 bis 20.00 Uhr",
            scheduler: "Klicken Sie auf Zeitplan",
            more: "Mehr",
        },
        lookupForm: {
            welcome: "Herzlich willkommen",
            info: "Tippen Sie auf eine, um einzuchecken",
            more: "Erzähl uns etwas über dich",
        },
        registrationForm: {
            header:
                "Tippen Sie auf den Vorwärtspfeil, wenn dies richtig aussieht",
        },
        contactForm: {
            header: "Wie sollen wir Sie kontaktieren?",
        },
        confirmationForm: {
            kiosk: {
                header: "Du bist eingecheckt!",
                content: "Nehmen Sie Platz und wir rufen Sie in Kürze an.",
            },
            online: {
                header: "Ihr Termin ist bestätigt!",
                qrCode: "Ihr QR-Code lautet:",
            },
        },
        consentForm: {
            header: "Zustimmung & Hinweis",
            show: "SHOW",
            hide: "AUSBLENDEN",
        },
        workflowStep: {
            what: "Was",
            when: "wann",
            where: "wo",
            service: "Bedienung",
            location: "Ort",
            time: "Zeit",
            register: "Registrieren",
            confirmed: "Bestätigt",
        },
        timeSlot: {
            header:
                "{dayPhase} - Wählen Sie für einen {dayPhase} -Termin das zukünftige Datum",
            morning: "Morgen {timeRange}",
            afternoon: "Nachmittag {timeRange}",
            evening: "Abend {timeRange}",
        },
        daySlots: {
            Today: "Heute",
            Monday: "Montag",
            Tuesday: "Dienstag",
            Wednesday: "Mittwoch",
            Thursday: "Donnerstag",
            Friday: "Freitag",
            Saturday: "Samstag",
            Sunday: "Sonntag",
            Beyond: "Darüber hinaus",
        },
        idField: {
            scanId: {
                scan: "ID scannen",
                unable: "Ihr Dokument kann nicht gescannt werden",
            },
            noId: "Keine ID",
        },
    },
};
