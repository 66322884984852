import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import SelectField from "../../fields/selectField/SelectField";
import FormTextField from "../../fields/textField/TextField";
import WorkflowState from "../../workflowState/WorfkflowState";
import PrevButton from "../../controlButtons/PrevButton";
import NextButton from "../../controlButtons/NextButton";
import { FormattedMessage } from "react-intl";

const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const mapStateToProps = (state) => {
    return {
        workflow: state.workflow,
    };
};

class ContactForm extends Component {
    static propTypes = {
        form: PropTypes.string.isRequired,
        step: PropTypes.number.isRequired,
    };

    renderContactSection() {
        return (
            <div className="container d-flex flex-column">
                <div className="m-0">
                    <WorkflowState completed={3} />
                </div>
                <div className="form-container">
                    <PrevButton />
                    <NextButton />
                    <div className="clearfix" />
                    <div className="row">
                        <div className="col">
                            <div className="col-12 head-info">
                                <p className="form-label">
                                    <FormattedMessage id="contactForm.header" />
                                </p>
                            </div>
                            <div className="col-md-12 form-row justify-content-center">
                                <div className="col-xs-12 col-md-6 col-lg-4 no-title">
                                    <SelectField label="user:communication:type" />
                                </div>
                                <div className="col-xs-12 col-md-6 col-lg-4"></div>
                            </div>
                            <div className="col-md-12 form-row justify-content-center">
                                <div className="col-xs-12 col-md-6 col-lg-4">
                                    <FormTextField
                                        label="user:email"
                                        mask={emailRegex}
                                    />
                                </div>
                                <div className="col-xs-12 col-md-6 col-lg-4">
                                    <FormTextField
                                        label="user:mobilePhone"
                                        phone
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    renderSection() {
        switch (this.props.workflow.form.sectionId) {
            case 0:
                return this.renderContactSection();
            default:
                console.log("Section not found");
        }
    }

    render() {
        return this.renderSection();
    }
}

ContactForm = connect(mapStateToProps)(ContactForm);
export default ContactForm;
