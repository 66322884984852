import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { workflowPreviousStep } from "../../actions/Workflow";
import Back from "./img/Back-Btn.png";

function PrevButton(props) {
    const prevButtonDisabled = useSelector(
        (state) => state.workflow.prevButtonDisabled
    );
    const dispatch = useDispatch();

    function classes() {
        if (prevButtonDisabled) {
            return "control-button control-left control-disabled";
        }
        return "control-button control-left";
    }

    function goPrev() {
        dispatch(workflowPreviousStep());
    }

    return (
        <div className={classes()} onClick={goPrev}>
            <img alt="Back Btn" src={Back} />
        </div>
    );
}

export default PrevButton;
