import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import store from "./store/index";
import "./scss/style.scss";
import LanguageProvider from "./containers/languageProvider/LanguageProvider";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";

import App from "./App";

const ENVIRONMENT = process.env.REACT_APP_ENVIRONMENT;
const VARIANT = process.env.REACT_APP_VARIANT;
const VERSION = process.env.REACT_APP_VERSION;

if (ENVIRONMENT !== "local") {
    Sentry.init({
        dsn:
            "https://78dfd1e48af34fbe9a6f9b8d372cf500@o995024.ingest.sentry.io/5953774",
        integrations: [new Integrations.BrowserTracing()],
        tracesSampleRate: 1.0,
        environment: ENVIRONMENT,
        beforeSend(event) {
            const workflow = store.getState().workflow;
            if (workflow?.uuid) {
                event.tags.workflow_uuid = workflow.uuid;
            }
            return event;
        },
    });
    Sentry.setTag("variant", VARIANT);
    Sentry.setTag("app-version", VERSION);
}

ReactDOM.render(
    <React.StrictMode>
        <Provider store={store}>
            <LanguageProvider>
                <App />
            </LanguageProvider>
        </Provider>
    </React.StrictMode>,
    document.getElementById("root")
);
