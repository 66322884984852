import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import FormTextField from "../../../fields/textField/TextField";
import MapField from "../../../fields/mapField/MapField";
import FormSelectField from "../../../fields/selectField/SelectField";
import LocationField from "../../../fields/locationField/LocationField";
import LocateField from "../../../fields/locateField/LocateField";
import { workflowNextStep } from "../../../../actions/Workflow";

const mapStateToProps = (state) => {
    return {
        workflow: state.workflow,
    };
};

const mapActionsToProps = (dispatch) => {
    return {
        workflowNextStep: (workflow) => dispatch(workflowNextStep(workflow)),
    };
};

class LocationSelectForm extends Component {
    static propTypes = {
        form: PropTypes.string.isRequired,
        step: PropTypes.number.isRequired,
    };

    goNext = () => {
        this.props.workflowNextStep();
    };

    renderForm = () => {
        const containerStyle = {
            maxWidth: "1146px",
            margin: "auto",
        };
        const { form } = this.props;
        return (
            <div className="content-center">
                <div className="row smaller-search-box">
                    <div className="location-change locate-container col justify-content-center mt-0">
                        <LocateField form={form} />
                        <FormTextField label="user:location" form={form} />
                        <FormSelectField
                            label="appointment:service:type"
                            form={form}
                        />
                        <div
                            className="btn btn-danger btn-large btn-go"
                            onClick={this.goNext}
                        >
                            Go
                        </div>
                    </div>
                </div>
                <div className="row" style={containerStyle}>
                    <div className="col-12 col-md-6">
                        <LocationField
                            label="appointment:location:proposal:1"
                            form={form}
                        />
                    </div>
                    <div className="col-12 col-md-6 d-none d-sm-block">
                        <MapField
                            label="appointment:location:map"
                            form={form}
                        />
                    </div>
                </div>
                <div className="row" style={containerStyle}>
                    <div className="col-12 col-md-6">
                        <LocationField
                            label="appointment:location:proposal:2"
                            form={form}
                        />
                    </div>
                    <div className="col-12 col-md-6">
                        <LocationField
                            label="appointment:location:proposal:3"
                            form={form}
                        />
                    </div>
                </div>
                <div className="row" style={containerStyle}>
                    <div className="col-12 col-md-6">
                        <LocationField
                            label="appointment:location:proposal:4"
                            form={form}
                        />
                    </div>
                    <div className="col-12 col-md-6">
                        <LocationField
                            label="appointment:location:proposal:5"
                            form={form}
                        />
                    </div>
                </div>
            </div>
        );
    };

    render() {
        return <div className="container-fluid">{this.renderForm()}</div>;
    }
}

LocationSelectForm = connect(
    mapStateToProps,
    mapActionsToProps
)(LocationSelectForm);
export default LocationSelectForm;
