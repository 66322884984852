import store from "../store/index";
import WorkflowService from "../services/Workflow";
export const WORKFLOW_LOAD = "WORKFLOW_LOAD";
export const WORKFLOW_NEXT = "WORKFLOW_NEXT";
export const WORKFLOW_PREV = "WORKFLOW_PREV";
export const UPDATE = "UPDATE";
export const SET_WORKFLOW_STATE = "SET_WORKFLOW_STATE";
export const TOGGLE_QR = "TOGGLE_QR";

export function initWorkflow(payload) {
    return function (dispatch) {
        const workflow = new WorkflowService(payload);
        dispatch(loadWorkflow(workflow));
        workflow.init(dispatch);
    };
}

export function loadWorkflow(payload) {
    return { type: WORKFLOW_LOAD, payload: payload };
}

export function workflowNextStep() {
    return function (dispatch) {
        return store.getState().workflow.nextStep(dispatch);
    };
}

export function workflowPreviousStep() {
    return function (dispatch) {
        return store.getState().workflow.prevStep(dispatch);
    };
}

export function update(payload) {
    return { type: UPDATE, payload };
}

export function setWorkflowState(payload) {
    return { type: SET_WORKFLOW_STATE, payload };
}

export function toggleQr() {
    return { type: TOGGLE_QR };
}
