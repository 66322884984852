import { English } from "./en-US";
import { German } from "./de-DE";
import { French } from "./fr-CA";
import { Spanish } from "./es-ES";
import { Polish } from "./pl-PL";
import { Japanese } from "./jpn";

export const messages = {
    ...English,
    ...German,
    ...French,
    ...Spanish,
    ...Polish,
    ...Japanese,
};
