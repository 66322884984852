import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { workflowNextStep } from "../../../../actions/Workflow";
import RangeSlider from "../../../buttons/rangeSlider/RangeSlider";

const mapStateToProps = (state) => {
    return {
        workflow: state.workflow,
    };
};
const mapActionsToProps = (dispatch) => {
    return {
        workflowNextStep: (workflow) => dispatch(workflowNextStep(workflow)),
    };
};

class SurveyForm extends Component {
    static propTypes = {
        form: PropTypes.string.isRequired,
        step: PropTypes.number.isRequired,
    };

    constructor(props) {
        super(props);
        this.state = { value: "" };
    }
    goNext = () => {
        this.props.workflowNextStep();
    };

    renderForm = () => {
        return (
            <div className="container d-flex flex-column">
                <div className="form-container survey-container">
                    <div className="clearfix" />
                    <div className="row">
                        <div className="col">
                            <div className="col-12">
                                <div className="current_place">
                                    <div className="first_dot"></div>
                                    <div className="second_dot"></div>
                                </div>
                                <div className="col-12 survey-header">
                                    <h1>
                                        <FormattedMessage id="satisfaction.header" />
                                    </h1>
                                </div>
                                <div className="survey-action">
                                    <div className=" plate-one">
                                        <span>
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                className="bi bi-emoji-frown"
                                                viewBox="0 0 16 16"
                                            >
                                                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                                                <path d="M4.285 12.433a.5.5 0 0 0 .683-.183A3.498 3.498 0 0 1 8 10.5c1.295 0 2.426.703 3.032 1.75a.5.5 0 0 0 .866-.5A4.498 4.498 0 0 0 8 9.5a4.5 4.5 0 0 0-3.898 2.25.5.5 0 0 0 .183.683zM7 6.5C7 7.328 6.552 8 6 8s-1-.672-1-1.5S5.448 5 6 5s1 .672 1 1.5zm4 0c0 .828-.448 1.5-1 1.5s-1-.672-1-1.5S9.448 5 10 5s1 .672 1 1.5z" />
                                            </svg>
                                        </span>
                                    </div>

                                    <div className="slider-place">
                                        <RangeSlider label="user:service:satisfaction" />
                                    </div>
                                    <div className="plate-two">
                                        <span>
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                className="bi bi-emoji-laughing"
                                                viewBox="0 0 16 16"
                                            >
                                                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                                                <path d="M12.331 9.5a1 1 0 0 1 0 1A4.998 4.998 0 0 1 8 13a4.998 4.998 0 0 1-4.33-2.5A1 1 0 0 1 4.535 9h6.93a1 1 0 0 1 .866.5zM7 6.5c0 .828-.448 0-1 0s-1 .828-1 0S5.448 5 6 5s1 .672 1 1.5zm4 0c0 .828-.448 0-1 0s-1 .828-1 0S9.448 5 10 5s1 .672 1 1.5z" />
                                            </svg>
                                        </span>
                                    </div>
                                </div>
                                <div className="col-12 button-side">
                                    <FormattedMessage id="satisfaction.submit">
                                        {(placeholder) => (
                                            <input
                                                type="submit"
                                                name="login"
                                                value={placeholder}
                                                className="btn btn-large btn-survey"
                                                onClick={this.goNext}
                                            />
                                        )}
                                    </FormattedMessage>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    render() {
        return <div className="container-fluid">{this.renderForm()}</div>;
    }
}

SurveyForm = connect(mapStateToProps, mapActionsToProps)(SurveyForm);
export default SurveyForm;
