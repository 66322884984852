import formLangPicker from "./langHelper";
import store from "../store/index";

/**
 * Interpolate string using passed values
 * @param {string} string - string to interpolate
 * @param {object} values - object containing data used in interpolation of text
 * @returns string
 */
export function interpolate(string, values) {
    return string.replace(/\{(\w+:?)+\}/g, (t) => {
        let tmp = t.slice(1, t.length - 1);
        return values ? values[tmp] : "";
    });
}

/**
 * Choose which text will be used and interpolate it with provided data
 * @param {object} section - section of text that will be translated and interpolated
 * @param {object} values - object containing data used in interpolation of text
 * @returns string
 */
export function interpolateTranslatedText(section) {
    const state = store.getState();
    const formId = state.workflow.displayStep.id;
    const form = state.workflow.forms[formId];
    const values = form.values;

    return interpolate(formLangPicker(section), values);
}
