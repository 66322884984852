import FormService from "./Form";
import { WorkflowStepService, WorkflowFormService } from "./Resources";
import { forkJoin, of } from "rxjs";
import { update, initWorkflow } from "../actions/Workflow";

export default class WorkflowService {
    constructor(workflow) {
        this.workflow = workflow;
        this.steps = null;
        this.uuid = null;
        this.displayStep = null;
        this.step = null;
        this.stepId = 0;
        this.forms = {};
        this.prevButtonDisabled = false;
    }

    init(dispatch) {
        this.dispatch = dispatch;
        this.updateWorkflow(this.workflow, this.nextStep);
    }

    updateWorkflow(workflow, callback) {
        this.steps = JSON.parse(workflow.steps);
        this.uuid = workflow.uuid;
        this.collectResources().subscribe(callback.bind(this));
    }

    collectResources() {
        let promises = [];

        for (let step of this.steps) {
            if (step.type === "form") {
                const formId = step.id;
                if (!this.forms[formId]) {
                    const promise = this.getForm(formId);
                    promises.push(promise);
                }
            }
        }

        if (promises.length > 0) {
            return forkJoin(promises);
        } else {
            return of(promises);
        }
    }

    getForm = (formId) => {
        return WorkflowFormService.get(
            {
                params: {
                    id: formId,
                },
            },
            this.setForm
        );
    };

    setForm = (response) => {
        this.forms[response.uuid] = new FormService(response);
    };

    nextStep() {
        if (this.step && this.step.type === "form") {
            if (this.form.validate()) {
                const exist = this.form.nextSection();
                if (exist) {
                    this.disablePrevButton(false);
                    this.dispatch(update());
                    return;
                }
            } else {
                this.disablePrevButton(false);
                this.dispatch(update());
                return;
            }
        }
        let offset = 1;
        try {
            this.getStep(offset);
        } catch (e) {
            this.exitWorkflow();
        }
    }

    isPrevStepAvailable() {
        this.disablePrevButton(false);
        let prevSection = this.form.prevSection();
        if (prevSection) {
            this.dispatch(update());
            return true;
        }
        let offset = -2;
        let prevStepData = this.steps[this.stepId + offset];
        if (prevStepData === undefined) {
            console.log("Previous step is unavailable");
            this.disablePrevButton(true);
            return false;
        } else if (prevStepData && prevStepData.type === "action") {
            console.log("Previous step is action, you cant go back");
            this.disablePrevButton(true);
            return false;
        }
        return true;
    }

    prevStep() {
        let offset = -1;
        if (this.isPrevStepAvailable() === false) return;
        try {
            this.getStep(offset);
        } catch (e) {
            this.exitWorkflow();
        }
    }

    disablePrevButton(value) {
        this.prevButtonDisabled = value;
    }

    getStep(offset) {
        let stepId = this.stepId + offset;
        let step = this.steps[stepId - 1];

        if (!step) {
            throw new Error("Step not available");
        } else {
            this.changeStep(step, stepId);
        }
    }

    changeStep(step, stepId) {
        this.stepId = stepId;
        this.step = step;
        if (step.type === "action") {
            this.performAction(step);
        } else {
            this.displayStep = step;
            this.form = this.forms[step.id];
            this.isPrevStepAvailable();
            this.dispatch(update());
        }
    }

    getStepByIdentifier(identifier) {
        for (let i = this.step; i < this.workflow.steps.length; ++i) {
            let step = this.workflow.steps[i];
            if (step.identifier === identifier) {
                this.changeStep(step, i + 1);
            }
        }
    }

    getValueById(id) {
        let forms = Object.values(this.forms);
        forms = forms.map((form) => form.values);
        forms = Object.assign({}, ...forms);
        return forms[id];
    }

    exitWorkflow() {
        console.log("exit workflow");
    }

    completeAction = (response) => {
        this.updateWorkflow(response, this.nextStep);
    };

    getSubmittableForms() {
        const data = {};
        for (let formId in this.forms) {
            const form = this.forms[formId];
            data[form.uuid] = form.getValues();
        }
        return data;
    }

    performAction(step) {
        const actionName = step.action_name;
        const actionMethod = step.method;
        let data = step.data;

        if (actionMethod === "change_workflow") {
            this.switchWorkflow(data);
            return;
        }

        if (data === "forms") {
            data = this.getSubmittableForms();
        }

        WorkflowStepService[actionMethod](
            {
                params: {
                    id: this.uuid,
                    action: "action",
                    actionId: actionName,
                },
                data: data,
            },
            this.completeAction
        );
    }

    switchWorkflow(data) {
        WorkflowStepService.get(
            {
                params: {
                    id: data,
                },
            },
            this.dispatchWorkflow
        );
    }

    dispatchWorkflow = (response) => {
        this.dispatch(initWorkflow(response));
    };
}
