import React, { useEffect } from "react";
import PropTypes from "prop-types";
import moment from "moment";

const AilaTechScanner = ({ onScan, onError, onClose }) => {
    const ailatech = window.AilaCordovaPlugin; // eslint-disable-line

    const initializeAilaTech = () => {
        ailatech.init().then(() => {
            ailatech
                .setConfig(
                    true,
                    "PadlocScanScanningModeSingle",
                    "PadlocScanMotionDetectionModeAutoconfigure",
                    "PadlocScanBeepModeOn",
                    "PadlocScanCardDetectionModeOff",
                    ["PadlocScanCodeTypePDF417"]
                )
                .then(() => {
                    ailatech
                        .scanStart()
                        .catch(onError);
                    ailatech.getNextScan().then(onAilaScan).catch(onError);
                })
                .catch(onError);
        });
    };

    const onAilaScan = (data) => {
        ailatech.scanStop();
        const slices = data[1].split("\n");
        const results = {};

        for (let i = 0; i < slices.length; ++i) {
            let [key, value] = ailaDecode(slices[i]);
            if (key) {
                results[key] = value;
            }
        }
        onScan(results);
    };

    const ailaDecode = (value) => {
        const knownCodes = {
            DCS: "lastName",
            DCT: "firstName",
            DBB: "dob",
        };
        for (let key in knownCodes) {
            if (key === value.substring(0, 3)) {
                let fieldValue = value.substring(3, value.length);
                if (knownCodes[key] === "dob") {
                    fieldValue =
                        fieldValue.substring(0, 2) +
                        "/" +
                        fieldValue.substring(2, 4) +
                        "/" +
                        fieldValue.substring(4, 8);
                    fieldValue = moment(fieldValue, "DD/MM/YYYY").format("DD-MM-YYYY");
                } else if (knownCodes[key] === "firstName") {
                    fieldValue = fieldValue.split(" ")[0];
                }
                return [knownCodes[key], fieldValue];
            }
        }

        return [null, null];
    };

    const renderAilatechOverlay = () => {
        return (
            <div className="ailatech-overlay">
                <div
                    className="ailatech-close"
                    onClick={onClose}
                >
                    ✕
                </div>
                <div className="ailatech-instructions">
                    Scan your ID barcode here
                    <br />v
                </div>
            </div>
        );
    };

    useEffect(() => {}, [], initializeAilaTech);

    return (
        <div className="scan-your-id-field">
            {renderAilatechOverlay()}
        </div>
    );
};

export default AilaTechScanner;

AilaTechScanner.propTypes = {
    onScan: PropTypes.func.isRequired,
    onError: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
};
