import { LOCALES } from "../constants";

export const Polish = {
    [LOCALES.POLISH]: {
        buttons: {
            login: "Zaloguj się",
            logout: "Wyloguj",
            close: "Zamknij",
            more: "wiecej ▼",
            less: "zwiń ▲",
            locate: "Zlokalizuj",
            go: "Dalej",
        },
        kiosk: {
            username: "Użytkownik",
            password: "Hasło",
            new: "Stwórz nowe konto",
            forgot: "Zapomniałeś hasła?",
            searchInput: "Szukaj...",
            popup: {
                timeout: "Koniec czasu za",
                seconds: "sekund",
                continue: "kontynuuj",
            },
        },
        errors: {
            usernameError: "Nieprawidłowa nazwa użytkownika",
            usernameEmpty: "Nazwa użytkownika wymagana",
            passwordEmpty: "Wymagane Hasło",
            popup: {
                loginFailedTitle: "Logowanie nie powiodło się",
                loginFailed:
                    "Proszę podać poprawną nazwę użytkownika i hasło. ",
            },
        },
        nav: {
            button: "Logowanie",
            language: {
                french: "Français",
                german: "Deutsche",
                spanish: "Española",
                english: "English",
                polish: "Polski",
                japanese: "日本語",
            },
        },
        menu: {
            patients: "Pacjent{br} indywidualny",
            healthcare: "Służba zdrowia{br} i szpitale",
            corpoClient: "Klient{br} Biznesowy",
            insurance: "Ubezpieczenia",
        },
        satisfaction: {
            header: "Czy sprawiliśmy, że jesteś szczęśliwy?",
            feedbackGreatv1:
                "Będziemy wdzięczni, jeśli poświęcisz chwilę na podzielenie się swoimi doświadczeniami w mediach społecznościowych. ",
            feedbackGreatv2:
                "Ta informacja zwrotna pomaga nam poprawić naszą zdolność zapewniania najlepszej możliwej opieki.",
            feedbackPoorly: "Dziękujemy za przekazanie nam swojej opinii",
            submit: "Zatwierdź",
        },
        errorPage: {
            notFound:
                "Przepraszamy, nie mogliśmy znaleźć strony, której szukasz. ",
            another:
                "Wygląda na to, że coś poszło nie tak. Spróbuj ponownie później lub skontaktuj się z nami. ",
        },
        service: {
            title: "Canada’s Healthcare Diagnostics & Testing Company",
            info: "Laboratoria i spotkania",
            description: "Znajdź najbliższą lokalizację i umow się",
        },
        location: {
            notFound: "Lokalizacja nie znaleziona",
        },
        locationField: {
            openingHours: "Otwarte od godziny 8:00",
            openingClosingHours: "Otwarte od godziny 8:00 do 20:00pm",
            scheduler: "Kliknij, aby zaplanować",
            more: "Więcej",
        },
        lookupForm: {
            welcome: "Witaj",
            info: "wybierz opcję, aby zameldować się",
            more: "Opowiedz nam trochę o sobie",
        },
        registrationForm: {
            header: "Przejdź dalej, jeśli wygląda dobrze",
        },
        contactForm: {
            header: "Jak mamy się z tobą skontaktować?",
        },
        confirmationForm: {
            kiosk: {
                header: "Jestes zarejestrowany!",
                content: "Usiądź, a wkrótce oddzwonimy.",
            },
            online: {
                header: "Twoja wizyta została potwierdzona!",
                qrCode: "Twój kod QR to:",
            },
        },
        consentForm: {
            header: "Zgoda i zawiadomienie",
            show: "POKAŻ",
            hide: "UKRUJ",
        },
        workflowStep: {
            what: "co",
            when: "kiedy",
            where: "gdzie",
            service: "Usługa",
            location: "Lokalizacja",
            time: "Czas",
            register: "Rejestracja",
            confirmed: "Potwierdzam",
        },
        timeSlot: {
            header:
                "{dayPhase} - w przypadku spotkania {dayPhase} wybierz przyszłą datę",
            morning: "Ranek {timeRange}",
            afternoon: "Popołudnie {timeRange}",
            evening: "Wieczór {timeRange}",
        },
        daySlots: {
            Today: "Dzisiaj",
            Monday: "Poniedziałek",
            Tuesday: "Wtorek",
            Wednesday: "Środa",
            Thursday: "Czwarte",
            Friday: "Piątek",
            Saturday: "Sobota",
            Sunday: "Niedziela",
            Beyond: "Więcej",
        },
        idField: {
            scanId: {
                scan: "Skanuj ID",
                unable: "Nie można zeskanować dokumentu",
            },
            noId: "brak ID",
        },
    },
};
