import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import TextField from "@material-ui/core/TextField";
import { interpolateTranslatedText } from "../../../helpers/interpolationHelper";
import NumberFormat from "react-number-format";

const mapStateToProps = (state) => {
    return {
        workflow: state.workflow,
        date: state.date,
        lang: state.selectedLanguage,
    };
};

class FormTextField extends Component {
    static propTypes = {
        label: PropTypes.string.isRequired,
    };

    constructor(props) {
        super(props);
        this.state = {
            field: this.props.workflow.form.getField(this.props.label),
        };
    }

    handleChange = (event) => {
        const target = event.target;
        const value = target.value;
        const field = this.state.field;
        field.value = value;
        field.error = !value;
        this.setState({
            field: field,
        });
    };

    NumberFormatCustom = React.forwardRef(function NumberFormatCustom(
        props,
        ref
    ) {
        const { onChange, ...other } = props;

        return (
            <NumberFormat
                {...other}
                getInputRef={ref}
                onValueChange={(values) => {
                    onChange({
                        target: {
                            name: props.name,
                            value: values.value,
                        },
                    });
                }}
                format="(###)-###-####"
                mask="_"
            />
        );
    });
    render() {
        const { field } = this.state;
        return (
            <>
                <TextField
                    label={interpolateTranslatedText(field.title)}
                    name={field.identifier}
                    onChange={this.handleChange}
                    value={field.value}
                    className="text-field"
                    error={field.error}
                    variant="standard"
                    InputProps={
                        this.props.phone
                            ? {
                                  value: field.value,
                                  inputComponent: this.NumberFormatCustom,
                              }
                            : null
                    }
                />
            </>
        );
    }
}

FormTextField = connect(mapStateToProps)(FormTextField);
export default FormTextField;
