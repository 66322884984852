import React, { Component } from "react";
import RedLeafLabsLogo from "./img/RedLeafLabsLogo.png";
import { FormattedMessage } from "react-intl";

class Menu extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isActive: false,
        };
    }

    renderMenuItem = (item, idx) => {
        return (
            <div className="menu-item" key={idx}>
                {item}
            </div>
        );
    };

    renderMenuItems = () => {
        const items = [
            <span>
                <FormattedMessage id="menu.patients" values={{ br: <br /> }} />
            </span>,
            <span>
                <FormattedMessage
                    id="menu.healthcare"
                    values={{ br: <br /> }}
                />
            </span>,
            <span>
                <FormattedMessage
                    id="menu.corpoClient"
                    values={{ br: <br /> }}
                />
            </span>,
            <span>
                <FormattedMessage id="menu.insurance" values={{ br: <br /> }} />
            </span>,
        ];

        return items.map(this.renderMenuItem);
    };

    showHamburgerMenuItems = () => {
        this.setState({
            isActive: !this.state.isActive,
        });
    };

    render() {
        function refreshPage() {
            window.location.reload(false);
        }

        const logo = {
            margin: "0 130px 0 34px",
            cursor: "pointer",
        };
        const logoToHamburger = {
            margin: "-10px 0 0 64px",
            cursor: "pointer",
        };
        return (
            <>
                <div className=" menu d-none d-lg-block">
                    <img
                        onClick={refreshPage}
                        src={RedLeafLabsLogo}
                        alt="Red leaf labs logo"
                        className="float-left"
                        style={logo}
                    />
                    {this.renderMenuItems()}
                </div>

                <div className="menu hamburger-menu-container d-block d-lg-none">
                    <img
                        onClick={refreshPage}
                        src={RedLeafLabsLogo}
                        alt="Red leaf labs logo"
                        className="float-left"
                        style={logoToHamburger}
                    />
                    <div
                        className="hamburger-menu-icon"
                        onClick={this.showHamburgerMenuItems}
                    >
                        <div className="hamburger-menu-icon-item"></div>
                        <div className="hamburger-menu-icon-item"></div>
                        <div className="hamburger-menu-icon-item"></div>
                    </div>
                    {this.state.isActive && this.renderMenuItems()}
                </div>
            </>
        );
    }
}

export default Menu;
