import { emailRegex } from "../constants/validationRules";

export default class FormService {
    constructor(form) {
        this.template = JSON.parse(form.form_template);
        this.identifier = form.identifier;
        this.uuid = form.uuid;
        this.values = JSON.parse(form.values);
        this.sections = this.getSections();
        this.sectionId = 0;
        this.section = this.template.sections[0];
    }

    getSections() {
        for (let section of this.template.sections) {
            section.fields = this.getFields(section);
        }
        return this.template.sections;
    }

    getSection(idx) {
        this.getValues();
        return this.sections[idx];
    }

    getFields(section) {
        const fields = {};
        for (let field of section.fields) {
            this.setFieldDefaultValue(field);
            fields[field.identifier] = field;
        }
        return fields;
    }

    setFieldDefaultValue(field) {
        if (this.values[field.identifier] !== undefined) {
            field.value = this.values[field.identifier];
        } else if (field.defaultValue !== undefined) {
            field.value = field.defaultValue;
        } else {
            field.value = "";
        }
    }

    validateField(field) {
        let regexp = "";

        switch (field.validationRule) {
            case "email":
                regexp = new RegExp(emailRegex);
                break;
            default:
                regexp = new RegExp(field.validationRule);
        }

        return regexp.test(field.value);
    }

    validate() {
        let error = false;
        for (let fieldId in this.section.fields) {
            const field = this.section.fields[fieldId];
            const value = field.value;

            if (field.required && (value === undefined || value === "")) {
                field.error = true;
                error = true;
            } else if (
                value &&
                field.validationRule &&
                !this.validateField(field)
            ) {
                field.error = true;
                error = true;
            } else {
                field.error = false;
            }
        }

        return !error;
    }

    nextSection() {
        const section = this.sectionLookup(1);
        if (section && this.section.display === false) {
            return this.nextSection();
        }
        return section;
    }

    prevSection() {
        return this.sectionLookup(-1);
    }

    getValues() {
        const values = {};
        for (let section of this.sections) {
            if (section.display !== false) {
                for (let fieldId in section.fields) {
                    const field = section.fields[fieldId];
                    if (values[field.identifier] && !field.value) {
                        continue;
                    }
                    values[field.identifier] = field.value;
                }
            }
        }
        this.values = values;
        return values;
    }

    getField(identifier, section) {
        if (section === undefined) {
            section = this.section;
        }
        const field = section.fields[identifier];
        this.setFieldDefaultValue(field);
        return field;
    }

    sectionLookup(offset) {
        const sectionId = this.sectionId + offset;
        const section = this.template.sections[sectionId];
        if (section) {
            this.sectionId = sectionId;
            this.section = section;
        }
        return !!section;
    }

    getStepByLabel(label) {
        for (let i = this.step; i < this.workflow.steps.length; ++i) {
            let step = this.workflow.steps[i];
            if (step.label === label) {
                this.changeStep(step, i + 1);
            }
        }
    }

    exitWorkflow() {
        console.log("exit workflow");
    }
}
