import React from "react";
import CmeterUI, { IconWrapper } from "@solvemd/cmeter-reactjs";
import { connect } from "react-redux";
import { ReactComponent as InternetIcon } from "../../configCmeter/cmeter_imgs/internetIcon.svg";
import { ReactComponent as WifiIcon } from "../../configCmeter/cmeter_imgs/wifiIcon.svg";

const mapStateToProps = (state) => {
    return {
        cmeterStatus: state.cmeterStatus,
    };
};
const CheckConnection = (props) => {
    const { cmeterStatus } = props;
    const icons = [
        {
            name: "local-check",
            icon: (
                <IconWrapper>
                    <WifiIcon />
                </IconWrapper>
            ),
        },
        {
            name: "internet-check",
            icon: (
                <IconWrapper>
                    <InternetIcon />
                </IconWrapper>
            ),
        },
    ];
    return (
        <div className="check-connection">
            <CmeterUI cmeterStatus={cmeterStatus} icons={icons} />
        </div>
    );
};

export default connect(mapStateToProps)(CheckConnection);
