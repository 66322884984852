import React from "react";
import { connect } from "react-redux";
import { FormattedMessage } from "react-intl";
import { loggingOut } from "../../actions/auth.actions";

const mapActionsToProps = (dispatch) => {
    return {
        logout: (isLogin) => dispatch(loggingOut(isLogin)),
    };
};
const mapStateToProps = (state) => {
    return {
        isLogin: state.isLogin,
    };
};
const AppSettings = (props) => {
    return (
        <>
            {props.isLogin && (
                <div className="appSettings">
                    <div
                        className="btn logout-button"
                        onClick={() => props.logout()}
                    >
                        <FormattedMessage id="buttons.logout" />
                    </div>
                </div>
            )}
        </>
    );
};

export default connect(mapStateToProps, mapActionsToProps)(AppSettings);
