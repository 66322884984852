import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import img1 from "./img/img1.jpg";
import FormTextField from "../../../fields/textField/TextField";
import FormSelectField from "../../../fields/selectField/SelectField";
import LocateField from "../../../fields/locateField/LocateField";
import { workflowNextStep } from "../../../../actions/Workflow";
import { FormattedMessage } from "react-intl";

const mapStateToProps = (state) => {
    return {
        workflow: state.workflow,
    };
};

const mapActionsToProps = (dispatch) => {
    return {
        workflowNextStep: (workflow) => dispatch(workflowNextStep(workflow)),
    };
};

class ServiceSelectForm extends Component {
    static propTypes = {
        form: PropTypes.string.isRequired,
        step: PropTypes.number.isRequired,
    };

    goNext = () => {
        this.props.workflowNextStep();
    };

    renderLeftCol = () => {
        return (
            <div className="service-container pt-4 ml-3">
                <p className="service-title pt-1">
                    <FormattedMessage id="service.title" />
                </p>
                <p className="service-info pt-1">
                    <FormattedMessage id="service.info" />
                </p>
                <p className="service-description">
                    <FormattedMessage id="service.description" />
                </p>
                <div className="locate-container">
                    <LocateField />
                    <FormTextField label="user:location" />
                    <div className="select-service-type">
                        <FormSelectField label="appointment:service:type" />
                        <div
                            className="btn btn-danger btn-large btn-go"
                            onClick={this.goNext}
                        >
                            <FormattedMessage id="buttons.go" />
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    render() {
        return (
            <div className="container-fluid select-service">
                <div className="row ml-0">
                    <div className="col-12 pl-0 col-xl-6">
                        {this.renderLeftCol()}
                    </div>
                    <div className="col-xl-6 d-none d-xl-block img-covid">
                        <img src={img1} alt="img" />
                    </div>
                </div>
            </div>
        );
    }
}

ServiceSelectForm = connect(
    mapStateToProps,
    mapActionsToProps
)(ServiceSelectForm);
export default ServiceSelectForm;
