import { LOCALES } from "../constants";

export const Japanese = {
    [LOCALES.JAPANESE]: {
        buttons: {
            login: "ログイン",
            logout: "ログアウト",
            close: "閉じるボタン",
            more: "もっと見る ▼",
            less: "表示を減らす ▲",
            locate: "見つける",
            go: "次",
        },
        kiosk: {
            username: "ユーザー名",
            password: "パスワード",
            new: "新しいアカウントを作る",
            forgot: "パスワードを忘れた場合",
            searchInput: "検索する",
            popup: {
                timeout: "タイムアウト",
                seconds: "秒",
                continue: "継続する",
            },
        },
        errors: {
            usernameError: "無効なユーザー名",
            usernameEmpty: "ユーザー名が必要です",
            passwordEmpty: "パスワードが必要です",
            popup: {
                loginFailedTitle: "ログインに失敗しました",
                loginFailed:
                    "正しいユーザー名とパスワードを入力してください。 ",
            },
        },
        nav: {
            button: "ログイン",
            language: {
                french: "Français",
                german: "Deutsche",
                spanish: "Española",
                english: "English",
                polish: "Polski",
                japanese: "日本語",
            },
        },
        menu: {
            patients: "患者",
            healthcare: "健康管理と病院",
            corpoClient: ",取引先",
            insurance: "保健",
        },
        satisfaction: {
            header: "私たちはあなたを幸せにしましたか？",
            feedbackGreatv1:
                "ソーシャルメディアでの経験を共有していただければ幸いです。 ",
            feedbackGreatv2:
                "このフィードバックは、可能な限り最善のケアを提供する能力を向上させるのに役立ちます。",
            feedbackPoorly:
                "フィードバックをお寄せいただきありがとうございます",
            submit: "送信",
        },
        errorPage: {
            notFound:
                "申し訳ありませんが、お探しのページが見つかりませんでした。 ",
                another:
                "問題が発生したようです。しばらくしてからもう一度お試しいただくか、お問い合わせください。 ",
        },
        service: {
            title: "カナダの健康管理の診断学とテスティングの会社",
            info: "研究室と訪問",
            description: "一番近い場所を検索して、待ち合わせてください・",
        },
        location: {
            notFound: "所在不明",
        },
        locationField: {
            openingHours: "午前8時から開いています。",
            openingClosingHours: "午前8時から午後8時まで開いています。",
            scheduler: "スケジュール",
            more: "もっと見る",
        },
        lookupForm: {
            welcome: "歓迎",
            info: "wチェックインするために、このオプションを選択してください",
            more: "お客様のことについて教えてください。",
        },
        registrationForm: {
            header: "Pそれが正しければ、クリックしてください",
        },
        contactForm: {
            header: "どのように連絡すればいいですか。",
        },
        confirmationForm: {
            kiosk: {
                header: "J登録が完了しました。",
                content: "少々お待ちください。すぐ電話をいたします。",
            },
            online: {
                header: "ご予約が承認されました。",
                content:
                    "先に来る必要がありません。確認するために、IDまたはQRコードを使ってください。",
                footer: "お目にかかることを楽しみにしています。",
            },
        },
        consentForm: {
            header: "承認と通知",
            show: "もっと見る",
            hide: "表示を減らす",
        },
        workflowStep: {
            what: "何",
            when: "いつ",
            where: "どこ",
            service: "サービス",
            location: "場所",
            time: "時間",
            register: "登録",
            confirmed: "確認",
        },
        timeSlot: {
            header: "{dayPhase} ご希望の日付を選択してください",
            morning: "朝 {timeRange}",
            afternoon: "午後 {timeRange}",
            evening: "晩 {timeRange}",
        },
        daySlots: {
            Today: "今日",
            Monday: "月曜日",
            Tuesday: "火曜日",
            Wednesday: "水曜日",
            Thursday: "木曜日",
            Friday: "金曜日",
            Saturday: "土曜日",
            Sunday: "日曜日",
            Beyond: "ほかの日",
        },
        idField: {
            scanId: {
                scan: "IDスキャン",
                unable: "書類のスキャンができません。",
            },
            noId: "IDなし",
        },
    },
};
