import React from "react";
import { FormattedMessage } from "react-intl";
const ErrorPage = (props) => {
    return (
        <div className="error container d-flex flex-column">
            <div className="token-expired">
                <div className="token-expired-text">
                    
                        <h1 className="status-error">{props.isError.status}</h1>
                        {props.isError.status === 404 ? (
                            <h2>Page {props.isError.statusText}</h2>
                        ) : (
                            <h2>{props.isError.statusText}</h2>
                        )}
                    

                    <h5 className="constant-message">
                        {props.isError.status === 404 ? (
                            <p>
                                <FormattedMessage id="errorPage.notFound" />
                            </p>
                        ) : (
                            <p>
                                <FormattedMessage id="errorPage.another" />
                            </p>
                        )}
                    </h5>
                </div>
            </div>
        </div>
    );
};

export default ErrorPage;
