import React, { Component } from "react";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";

export class Slot extends Component {
    static propTypes = {
        text: PropTypes.string,
        active: PropTypes.bool.isRequired,
        handleClick: PropTypes.func.isRequired,
    };

    renderSlot = () => {
        const { active, handleClick, text, date } = this.props;

        return (
            <Grid
                item
                xs={5}
                sm={2}
                md={2}
                lg={2}
                xl={2}
                className="slotContainer"
            >
                <Paper
                    className={`slot ${active ? "active" : ""}`}
                    onClick={handleClick}
                >
                    {date ? date : <FormattedMessage id={`daySlots.${text}`} />}
                </Paper>
            </Grid>
        );
    };

    render() {
        return this.renderSlot();
    }
}

export default Slot;
