import React, { Component } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import DaySlots from "../daySlots/DaySlots";
import TimeSlots from "../timeSlots/TimeSlots";

const format = "h:mm A";

export class ScheduleWidget extends Component {
    static propTypes = {
        onChange: PropTypes.func,
        onDateChange: PropTypes.func.isRequired,
        slots: PropTypes.array,
    };
    constructor(props) {
        super(props);

        this.state = {
            morningStart: moment("8:00 AM", format),
            morningEnd: moment("11:55 AM", format),
            afternoonStart: moment("12:00 PM", format),
            afternoonEnd: moment("4:55 PM", format),
            eveningStart: moment("5:00 PM", format),
            eveningEnd: moment("9:00 PM", format),
            morningSlots: [],
            afternoonSlots: [],
            eveningSlots: [],
            chosenTimeSlot: this.props.value,
            chosenDate: null,
            appointmentDate: {},
            currentOpen: false,
        };
    }

    componentDidMount() {
        if (this.props.slots) {
            this.getTimeSlots();
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props !== prevProps) {
            if (this.props.slots) {
                this.getTimeSlots();
            }
        }
    }

    getTimeSlots() {
        const morningSlots = [],
            afternoonSlots = [],
            eveningSlots = [];

        this.props.slots.map((item) => {
            const {
                morningStart,
                morningEnd,
                afternoonStart,
                afternoonEnd,
                eveningStart,
                eveningEnd,
            } = this.state;
            const time = moment
                .parseZone(item.start_date_time)
                .format("h:mm A");

            if (
                moment(time, format).isBetween(
                    morningStart,
                    morningEnd,
                    null,
                    "[]"
                )
            ) {
                morningSlots.push(item);
            } else if (
                moment(time, format).isBetween(
                    afternoonStart,
                    afternoonEnd,
                    null,
                    "[]"
                )
            ) {
                afternoonSlots.push(item);
            } else if (
                moment(time, format).isBetween(
                    eveningStart,
                    eveningEnd,
                    null,
                    "[]"
                )
            ) {
                eveningSlots.push(item);
            }
            return null;
        });

        this.setState({
            morningSlots: morningSlots,
            afternoonSlots: afternoonSlots,
            eveningSlots: eveningSlots,
        });
    }

    onChange = (timeSlot) => {
        this.setState(
            {
                chosenTimeSlot: timeSlot,
            },
            () => {
                this.props.onChange(timeSlot);
            }
        );
    };

    onDateChange = (date) => {
        this.setState(
            {
                chosenDate: date,
            },
            () => {
                this.props.onDateChange(date);
            }
        );
    };

    getAppointmentDate = () => {
        this.props.onClick(this.state.appointmentDate);
    };

    getDays() {
        const days = [];
        for (let i = 0; i < 4; ++i) {
            days.push(moment().add(i, "days"));
        }

        return days;
    }

    toggleRefresh = () => {
        this.setState({
            currentOpen: !this.state.currentOpen,
        });
    };

    render() {
        const {
            morningStart,
            morningEnd,
            afternoonStart,
            afternoonEnd,
            eveningStart,
            eveningEnd,
        } = this.state;

        return (
            <div className="pb-5">
                <DaySlots
                    onDateChange={this.onDateChange}
                    slots={this.getDays()}
                />
                <TimeSlots
                    timeSlots={this.state.morningSlots}
                    onChange={this.onChange}
                    dayPhase={"morning"}
                    timeRange={`${morningStart.format(
                        "h:mm A"
                    )} - ${morningEnd.format("h:mm A")}`}
                    chosenTimeSlot={this.state.chosenTimeSlot}
                    refresh={this.state.currentOpen}
                    refreshState={() => {
                        this.toggleRefresh();
                    }}
                />
                <TimeSlots
                    timeSlots={this.state.afternoonSlots}
                    onChange={this.onChange}
                    dayPhase={"afternoon"}
                    timeRange={`${afternoonStart.format(
                        "h:mm A"
                    )} - ${afternoonEnd.format("h:mm A")}`}
                    chosenTimeSlot={this.state.chosenTimeSlot}
                    refresh={this.state.currentOpen}
                    refreshState={() => {
                        this.toggleRefresh();
                    }}
                />
                <TimeSlots
                    timeSlots={this.state.eveningSlots}
                    onChange={this.onChange}
                    dayPhase={"evening"}
                    timeRange={`${eveningStart.format(
                        "h:mm A"
                    )} - ${eveningEnd.format("h:mm A")}`}
                    chosenTimeSlot={this.state.chosenTimeSlot}
                    refresh={this.state.currentOpen}
                    refreshState={() => {
                        this.toggleRefresh();
                    }}
                />
            </div>
        );
    }
}

export default ScheduleWidget;
