import React, { Component } from "react";
import moment from "moment";
import DateFnsUtils from "@material-ui/lab/AdapterDateFns";
import LocalizationProvider from "@material-ui/lab/LocalizationProvider";
import { TextField } from "@material-ui/core";
import MobileDatePicker from "@material-ui/lab/MobileDatePicker";

export class BeyondDatePicker extends Component {
    state = {
        selectedDate: new Date(),
        isOpen: true,
    };

    handleDateChange = (date) => {
        this.setState({ selectedDate: date }, () => {
            this.props.date(date);
        });
    };

    render() {
        const { selectedDate, isOpen } = this.state;
        return (
            <LocalizationProvider dateAdapter={DateFnsUtils}>
                <MobileDatePicker
                    value={selectedDate}
                    onChange={this.handleDateChange}
                    minDate={moment().toDate()}
                    onClose={this.props.cancel}
                    onAccept={this.handleDateChange}
                    open={isOpen}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            style={{ display: "none" }}
                            className="text-field"
                            id="standard-basic"
                            helperText="yyyy/mm/dd"
                            variant="standard"
                        />
                    )}
                />
            </LocalizationProvider>
        );
    }
}

export default BeyondDatePicker;
