import { SET_LANGUAGE } from "../actions/language.actions";
import { SET_CMETER } from "../actions/cmeter.actions";
import {
    WORKFLOW_LOAD,
    UPDATE,
    SET_WORKFLOW_STATE,
    TOGGLE_QR,
} from "../actions/Workflow";
import { LOGIN, LOGOUT, SAVE_TOKEN } from "../actions/auth.actions";
import { DEFAULT_LANGUAGE } from "../i18n/configuration";

const initialState = {
    workflow: null,
    workflowState: {
        service: "",
        datetime: "",
        location: {
            address: "",
            name: "",
        },
    },
    date: new Date(),
    qrVisibility: false,
    isLogin: false,
    refresh_token: "",
    access_token: "",
    selectedLanguage: DEFAULT_LANGUAGE.ENGLISH,
    cmeterStatus: [],
};

function rootReducer(state = initialState, action) {
    switch (action.type) {
        case WORKFLOW_LOAD:
            return Object.assign({}, state, {
                workflow: action.payload,
            });
        case UPDATE:
            return Object.assign({}, state, {
                date: new Date(),
            });
        case SET_WORKFLOW_STATE:
            return Object.assign({}, state, {
                workflowState: {
                    ...state.workflowState,
                    ...action.payload,
                },
            });
        case TOGGLE_QR:
            return Object.assign({}, state, {
                qrVisibility: !state.qrVisibility,
            });
        case LOGIN:
            return Object.assign({}, state, {
                isLogin: true,
                refresh_token: action.payload.refresh_token,
                access_token: action.payload.access_token,
            });
        case LOGOUT:
            return Object.assign({}, state, {
                isLogin: false,
                workflow: null,
                workflowState: {
                    service: "",
                    datetime: "",
                    location: {
                        address: "",
                        name: "",
                    },
                },
                refresh_token: "",
                access_token: "",
            });
        case SAVE_TOKEN:
            return Object.assign({}, state, {
                isLogin: true,
                ...action.payload,
            });
        case SET_LANGUAGE:
            return {
                ...state,
                selectedLanguage: action.payload,
            };
        case SET_CMETER:
            return {
                ...state,
                cmeterStatus: action.payload,
            };
        default:
            return state;
    }
}

export default rootReducer;
