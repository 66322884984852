import React, { Component } from "react";
import { connect } from "react-redux";
import { initWorkflow, workflowNextStep } from "../../actions/Workflow";
import TimeSelectForm from "../../components/forms/selectTimeForm/Form";
import ServiceSelectForm from "../../components/forms/online/selectService/Form";
import LocationSelectForm from "../../components/forms/online/selectLocation/Form";
import LookupForm from "../../components/forms/online/lookupForm/Form";
import ContactForm from "../../components/forms/contactForm/Form";
import { LoginService, WorkflowService } from "../../services/Resources";
import ConsentForm from "../../components/forms/consentForm/Form";
import RegistrationForm from "../../components/forms/registrationForm/Form";
import ConfirmationForm from "../../components/forms/online/confirmationForm/Form";
import Loader from "../../components/loader/Loader";
import { saveAccessToken } from "../../actions/auth.actions";

const mapStateToProps = (state) => {
    return {
        workflow: state.workflow,
        date: state.date, // temporary to handle store update
    };
};

const mapActionsToProps = (dispatch) => {
    return {
        saveToken: (payload) => dispatch(saveAccessToken(payload)),
        workflowInit: (workflow) => dispatch(initWorkflow(workflow)),
        workflowNextStep: (workflow) => dispatch(workflowNextStep(workflow)),
    };
};

class WorkflowOnline extends Component {
    componentDidMount() {
        this.createSchelduiling();
        window.addEventListener("reload", this.createSchelduiling);
    }

    createSchelduiling = (response) => {
        const client_id = process.env.REACT_APP_ONLINE_CLIENT_ID;
        const client_secret = process.env.REACT_APP_ONLINE_CLIENT_SECRET;

        const formData = {
            grant_type: "client_credentials",
            client_id,
            client_secret,
        };

        const data = Object.keys(formData)
            .map((key) => `${key}=${encodeURIComponent(formData[key])}`)
            .join("&");

        LoginService.post(
            {
                params: {
                    enabled: undefined,
                },
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                },
                data,
            },
            this.onAuthenticationSuccess
        );
    };

    onAuthenticationSuccess = (data) => {
        this.props.saveToken(data);
        WorkflowService.post(
            {
                data: {
                    workflow_template_slug: "schedule_online",
                },
            },
            this.initializeWorkflow
        );
    };

    initializeWorkflow = (response) => {
        WorkflowService.post(
            {
                params: {
                    id: response.uuid,
                    action: "initialize",
                },
            },
            this.loadWorkflow
        );
    };

    loadWorkflow = (response) => {
        this.props.workflowInit(response);
    };

    renderStep = () => {
        const { workflow } = this.props;
        if (workflow.displayStep) {
            return <div>{this.renderForm()}</div>;
        }
    };

    renderForm = () => {
        const { workflow } = this.props;
        const { uuid, sectionId } = workflow.form;
        switch (workflow.form.identifier) {
            case "schedule_service_select":
                return <ServiceSelectForm form={uuid} step={sectionId} />;
            case "schedule_location_select":
                return <LocationSelectForm form={uuid} step={sectionId} />;
            case "schedule_time_select":
                return <TimeSelectForm form={uuid} step={sectionId} />;
            case "registration":
                return <RegistrationForm form={uuid} step={sectionId} />;
            case "lookup":
                return <LookupForm form={uuid} step={sectionId} />;
            case "communication":
                return <ContactForm form={uuid} step={sectionId} />;
            case "consents":
                return <ConsentForm form={uuid} step={sectionId} />;
            case "confirmation":
                return <ConfirmationForm form={uuid} step={sectionId} />;
            default:
                return (
                    <div>
                        <div>Form {workflow.form.identifier}</div>
                    </div>
                );
        }
    };

    renderView = () => {
        const { workflow } = this.props;
        if (!workflow) {
            return <Loader fixed={true} />;
        } else {
            return this.renderStep();
        }
    };

    render() {
        return <div className="workflow-container">{this.renderView()}</div>;
    }
}

WorkflowOnline = connect(mapStateToProps, mapActionsToProps)(WorkflowOnline);
export default WorkflowOnline;
