import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { workflowNextStep, setWorkflowState } from "../../../actions/Workflow";
import { ScheduleGroupService } from "../../../services/Resources";
import moment from "moment";
import momentTimezone from "moment-timezone";
import { FormattedMessage } from "react-intl";

const mapStateToProps = (state) => {
    return {
        workflow: state.workflow,
        workflowState: state.workflowState,
    };
};

const mapActionsToProps = (dispatch) => {
    return {
        workflowNextStep: (workflow) => dispatch(workflowNextStep(workflow)),
        setWorkflowState: (state) => dispatch(setWorkflowState(state)),
    };
};

class LocationField extends Component {
    static propTypes = {
        label: PropTypes.string.isRequired,
    };

    constructor(props) {
        super(props);
        this.state = {
            field: this.props.workflow.form.getField(this.props.label),
            slots: null,
            isToday: true,
        };
    }

    componentDidMount() {
        this.fetchSlots();
    }

    componentDidUpdate() {
        const field = this.props.workflow.form.getField(this.props.label);
        if (this.state.field.value !== field.value) {
            this.setState({ field }, this.fetchSlots);
        }
    }

    fetchSlots = (date) => {
        date = typeof date === "undefined" ? moment() : date;
        ScheduleGroupService.get(
            {
                params: {
                    id: this.state.field.value.uuid,
                    action: "slots",
                    duration: this.props.workflowState.service.value ? 10 : 15,
                    date: date.format("YYYY-MM-DD"),
                    resolution: 5,
                },
            },
            this.setSlots
        );
    };

    setSlots = (response) => {
        this.setState({
            slots: response.slots,
        });
    };

    onClick = () => {
        const { field } = this.state;
        this.props.workflow.form.getField("appointment:location").value =
            field.value.uuid;
        this.props.setWorkflowState({ location: field.value });
        if (field.triggerNext) {
            this.props.workflowNextStep();
        }
    };

    selectSlot = (slot) => {
        const { slots } = this.state;
        slot.selected = true;
        this.props.workflow.form.getField("appointment:datetime").value = {
            start_date_time: slot.start_date_time,
            schedule: slot.schedule,
        };
        this.props.setWorkflowState({ datetime: slot.start_date_time });
        this.setState({ slots });
    };

    renderSlot(slot) {
        return (
            <div className="col-4" key={slot.start_date_time}>
                <div
                    className={`location-slot ${
                        slot.selected ? "selected-location-slot" : ""
                    }`}
                    onClick={() => {
                        this.selectSlot(slot);
                    }}
                >
                    {moment.parseZone(slot.start_date_time).format("h:mm A")}
                </div>
            </div>
        );
    }

    renderSlots() {
        const { slots } = this.state;
        if (!slots) {
            return <p className="location-information">Loading...</p>;
        } else if (!slots.length && this.state.isToday) {
            this.setState({
                isToday: false,
            });
            this.fetchSlots(moment().add(1, "days"));
        } else if (!slots.length) {
            return (
                <p className="location-information">
                    Sorry we have no availability at this location.
                </p>
            );
        }

        const slotElmts = [];
        for (let i = 0; i < 5; ++i) {
            if (slots[i]) {
                slotElmts.push(this.renderSlot(slots[i], i));
            } else {
                break;
            }
        }

        const { timezone } = this.state.field.value;
        var hour = parseInt(momentTimezone().tz(timezone).format("H"));
        var openingHours = <FormattedMessage id="locationField.openingHours" />;
        if (hour >= 8 && hour < 20) {
            openingHours = (
                <FormattedMessage id="locationField.openingClosingHours" />
            );
        }

        return (
            <div>
                <div className="col-12">
                    <p className="location-open mb-0">
                        {openingHours}
                        <br />
                        <FormattedMessage id="locationField.scheduler" />
                    </p>
                </div>
                <div className="location-slots">
                    <div className="row">
                        {slotElmts}
                        <div className="col-4">
                            <div className="location-slot-more">
                                <FormattedMessage id="locationField.more" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    renderDistance = () => {
        const { field } = this.state;
        return (
            <div className="point col-4">
                {field.value.distance.toFixed(2)}km
            </div>
        );
    };

    render() {
        const { field } = this.state;
        return (
            <div className="location-field" onClick={this.onClick}>
                <div className="row">
                    <div className="col-8">
                        <p className="location-name">{field.value.name}</p>
                        <p className="location-address">
                            {field.value.address}
                        </p>
                        <p className="location-address">
                            {field.value.city}, {field.value.province}
                        </p>
                    </div>
                    {this.renderDistance()}
                    {this.renderSlots()}
                </div>
            </div>
        );
    }
}

LocationField = connect(mapStateToProps, mapActionsToProps)(LocationField);
export default LocationField;
